import { createSlice } from '@reduxjs/toolkit'
interface slectedThemeState {
  themeName: 'dark' | 'light'
}

const initialState: slectedThemeState = {
  themeName: 'dark',
}

const selectedTheme = createSlice({
  name: 'selectedTheme',
  initialState,
  reducers: {
    updateSelectedTheme(state, action) {
      state.themeName = action.payload.themeName
      console.log(action)
    },
  },
});

const {actions,reducer} = selectedTheme;
const {updateSelectedTheme} = actions;
export {updateSelectedTheme};
export default reducer;
