import { Box, Button, Stack } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import React, { useState } from 'react'
import './styles.css'
import Container from '@mui/material/Container'
import VideoDisplay from '../VideoDisplay'
import Modal from '@mui/material/Modal'
import { useSelector } from 'react-redux'
import PhoneCarousel from '../PhoneCarousel/PhoneCarousel'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
}

export default function CTASection() {
  /**-------------------------------------- */
  /**STYLE MEDIA QUERIES HERE */
  /**-------------------------------------- */
  const playButtonIconStyle = ()=>{
    const xSmall = window.matchMedia("(max-width: 575px) and (orientation: portrait)")
    if (xSmall.matches) {
      if (themeName === 'light') {
        return { fontSize: '30pt', color: '#404040' };
      }else{
        return { fontSize: '30pt', color: '#FF9900' };
      }
      
    }
    

    //Default in case no one is met
      if (themeName === 'light') {
        return { fontSize: '60pt', color: '#404040' };
      }else{
        return { fontSize: '60pt', color: '#FF9900' };
      }
  };

  const getStartedButtonStyle = ()=>{
    const xSmall = window.matchMedia("(max-width: 575px) and (orientation: portrait)")
    if (xSmall.matches) {
      return { height: 'fit-content', fontFamily: 'Montserrat',fontSize:"6pt" };
    }

    //Default in case no one is met
    return { height: 'fit-content', fontFamily: 'Montserrat',fontSize:"12pt" }
  }
  /**-------------------------------------- */
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  /**-------------------------------------- */
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  return (
    <div className="cta_section--main_container">
      <div className="cta_section--main_content_container">
        <div className="cta_section--left_container">
          {/* <img
            className="cta_section--background_image"
            src="sat_map.png"
            alt=""
          /> */}
          <p
            {...(themeName === 'light'
              ? { className: 'cta_section--title' }
              : { className: 'cta_section--title_dark' })}
          >
            Effortless Fleet Management at Your Fingertips
          </p>
          <p {...(themeName === 'light'
              ? { className: 'cta_section--description' }
              : { className: 'cta_section--description_dark' })}>
            Discover a smarter way to oversee your fleet's movements with our
            state-of-the-art tracking solution. Say goodbye to the hassle of
            manual tracking and embrace real-time insights, proactive
            notifications, and insightful reports—all in one place.
          </p>
          <div className="cta_section--action_buttons_container">
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              style={getStartedButtonStyle()}
            >
              Get Started
            </Button>
            <div className="cta_section--show_video_container">
              <Button
                onClick={handleOpen}
                startIcon={
                  <PlayCircleOutlineIcon className='cta_section--play_video_icon'
                    style={playButtonIconStyle()}
                  />
                }
              >
                <p 
                  {...(themeName === 'light'
                  ? { className: 'cta_section--show_video_label' }
                  : { className: 'cta_section--show_video_label_dark' })}
                >
                  Show Video
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div className="cta_section--phone_carousel_container">
          <PhoneCarousel autoPlay={true} interval={5000} />
        </div>
      </div>
      <div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <VideoDisplay onClose={handleClose}/>
          </Box>
        </Modal>
      </div>
    </div>
  )
}
