import { Container, List, ListItem, ListItemIcon } from '@mui/material'
import { Stack } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import './styles.css'
import React from 'react'
import { useSelector } from 'react-redux'
type FeatureChipParams = {
  icon?:any,
  title:string,
  features:string[],
  linkURL?:string,
  iconColor?:string,
  bannerImage:string
}
export default function FeatureChip(params: FeatureChipParams) {
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  const renderFeatureList = params.features.map((feature) => {
    return (
      <ListItem style={{ padding: 0 }}>
        {/**AQUI COMENTE ESTO PORQUE NO VA A TENER VIÑETAS. SERA UN PARRAFITO */}
        {/* <ListItemIcon>
          <CheckCircleIcon className="feature_chip--CheckCircleIcon" />
        </ListItemIcon> */}
        <p className="feature_chip_feature">
          {feature}
        </p>
      </ListItem>
    )
  })
  return (
    <div className='feature_chip--main_container'>
      
      <div
        {...(themeName === 'light'
          ? { className: 'feature_chip' }
          : { className: 'feature_chip_dark' })}
      >
        <img className='feature_chip--banner_image' src={params.bannerImage} alt="" />
        <div className='feature_chip--icon_feature_contanier'>
          
        {params.icon}
        <p className="feature_chip--Title">{params.title}</p>
        </div>
        <List className="feature_chip--feature-list">{renderFeatureList}</List>
      </div>
    </div>
  )
}
