import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Device, secureCompany, secureDevice } from '../../api/types'
import { getAll, updateDevice, updateItem } from '../../api/api_functions'
import {} from '../drivers/DriversList'

let devices: Device[] = []

let initialState = {
  devices: devices,
  status: 'idle',
  error: '',
}

export const fetchDevices = createAsyncThunk(
  'devices/fetchDevices',

  async (obj: secureCompany) => {
    const response = await getAll<Device>(
      'device',
      obj.organizationId,
      obj.token,
    )
    // response.forEach((element) => {
    //
    // })

    return response
  },
)

export const deviceUpdated = createAsyncThunk(
  'devices/deviceUpdated',

  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureDevice) => {
    // We send the initial data to the fake API server
    const response = await updateDevice(
      'device',
      obj.device,
      obj.token,
      obj.organizationId,
    )
    // The response includes the complete post object, including unique ID
    return obj.device // need to reutn the vehcile to add to the redux  store
  },
)

const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDevices.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.devices = state.devices.concat(action.payload)
        state.status = 'succeeded'
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Drivers List could not be retrieved'
        // state.vehicles.error = action.error.message
        //
      })
      .addCase(deviceUpdated.fulfilled, (state, action) => {
        const updatedDevice: Device = action.payload

        const exitingDevice = state.devices.find(
          (dev) => dev.id === updatedDevice.id,
        )
        if (exitingDevice) {
          exitingDevice.name = updatedDevice.name
        }
        alert('Device updated sucessfully')
      })
      .addCase(deviceUpdated.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'error'
        // state.vehicles.error = action.error.message
        //
      })
  },
})

export const selectAllDevices = (state: { devices: { devices: Device[] } }) =>
  state.devices.devices

export default deviceSlice.reducer
