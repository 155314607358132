import { useState, useEffect, useRef } from 'react'

import * as React from 'react'

import GeofencesController from './geofences/GeofencesController'

import { Button } from '@mui/material'

import { Tab, Tabs } from 'react-bootstrap'

import { MapProps, renderedMarkers } from './../api/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './../app/store'

import { styles } from './mapstyles'
import useWindowSize from './../customHooks/useWindowSize'

import { selectAllVehicles } from './../features/vehicles/vehicleSlice'
import VehiclesControllers from './VehiclesController'

import { useLocation, useSearchParams } from 'react-router-dom'

import VehicleHistoryController from './VehicleHistoryController'

import MapLiveMarkerController from './MapLiveMarkerController'
import RightDrawer from './RightDrawer'
import { VehicleInfo } from './VehicleInfo'
import './MapController.css'
import CustomSwipeableDrawer from './CustomSwipeableDrawer/CustomSwipeableDrawer'

//do not delete
const iconbaseurl =
  'https://images-ea57cbc6-0253-11ed-b939-0242ac120002.s3.amazonaws.com/'
export interface Theme {}
export interface MapControllerprops extends MapProps {
  map: google.maps.Map | undefined
}

const drawerBleeding = 85

type condWrapperprops = {
  condition: any
  wrapper: (arg0: any) => any
  children: any
}

export const setMapOnAll = (
  map: google.maps.Map | null,
  markers: google.maps.Marker[],
) => {
  for (let i = 0; i < markers.length; i++) {
    markers[i].setMap(map)
  }
}

export const calculateMapCenterAndZoom = (livegeolocations: any[]) => {
  const coordinates: { lat: number; lng: number }[] = []
  console.log('lenght=', coordinates.length)

  livegeolocations.forEach((element) => {
    const lat = element.latitude
    const lng = element.longitude
    coordinates.push({ lat, lng })
  })

  if (!Array.isArray(coordinates)) {
    // Return default center and zoom if no coordinates are provided
    return { center: { lat: 0, lng: 0 }, zoom: 10 }
  }
  if (coordinates.length > 0) {
    // Initialize min and max values for latitude and longitude
    let minLat = coordinates[0].lat
    let maxLat = coordinates[0].lat
    let minLng = coordinates[0].lng
    let maxLng = coordinates[0].lng

    // Find the min and max values for latitude and longitude
    coordinates.forEach((coord) => {
      minLat = Math.min(minLat, coord.lat)
      maxLat = Math.max(maxLat, coord.lat)
      minLng = Math.min(minLng, coord.lng)
      maxLng = Math.max(maxLng, coord.lng)
    })

    // Calculate the center latitude and longitude using the average values
    const centerLat = (maxLat + minLat) / 2
    const centerLng = (maxLng + minLng) / 2

    // Calculate the distance between the center and the farthest coordinate
    const latRadian = (maxLat - minLat) * (Math.PI / 180)
    const lngRadian = (maxLng - minLng) * (Math.PI / 180)
    const distanceLat = 111.32 * latRadian // 1 degree of latitude is approximately 111.32 kilometers
    const distanceLng =
      111.32 * Math.cos(centerLat * (Math.PI / 180)) * lngRadian
    const maxDistance = Math.max(distanceLat, distanceLng)

    // Calculate the zoom level based on the distance

    const zoomFactor = 0.2 // Adjust this value to control how close the zoom should be (0.1 to 1, where 1 is no zoom reduction)
    const adjustedMaxDistance = maxDistance * zoomFactor

    //const zoom = Math.floor(Math.log2(360 / maxDistance))
    const zoom = Math.floor(Math.log2(360 / adjustedMaxDistance))

    // Return the calculated center and zoom level

    return { center: { lat: centerLat, lng: centerLng }, zoom }
  } else return { center: { lat: 0, lng: 0 }, zoom: 10 }
}

function MapController(props: MapControllerprops) {
  /*-------------------------------------------------------------------------- */
  /** CONSTANTS HERE                                                           */
  /*-------------------------------------------------------------------------- */
  const dispatch: any = useDispatch()

  /*-------------------------------------------------------------------------- */
  /** STATE VARIABLES HERE                                                     */
  /*-------------------------------------------------------------------------- */
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  let [searchParams, setSearchParams] = useSearchParams()

  const [openRightDrawer, setopenRightDrawer] = useState(false)
  const [rightDrawerMode, setRightDrawerMode] = useState<
    'geofence' | 'history' | '' | 'liveinfo' | 'event'
  >('')
  const windowSize = useWindowSize()
  const [openHistoryDrawer, setOpenHistoryDrawer] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [historydeviceID, setDeviceId] = React.useState('')

  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  let lastLocationMarker: renderedMarkers = {}
  const vehicles = useSelector(selectAllVehicles)

  const livegeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )
  const livegeolocationsstatus = useSelector(
    (state: RootState) => state.geolocations.status,
  )
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const [selectedTab, setSelectedTab] = useState('Vehicles') // Set the default tab

  let location = useLocation()
  const startD = searchParams.get('startDate')
  const endD = searchParams.get('endDate')
  const vehicleId = searchParams.get('vehicleId')
  const geofenceId = searchParams.get('geofenceId')

  let startDate: Date
  let endDate: Date

  if (location.pathname === '/GA' && startD && endD) {
    startDate = new Date(0)
    endDate = new Date(0)

    startDate.setUTCSeconds(Number(startD))
    endDate.setUTCSeconds(Number(endD))
  } else {
    startDate = new Date()
    endDate = new Date()

    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
  }

  useEffect(() => {
    let device
    if (vehicleStatus === 'succeeded')
      device = vehicles.find((vehicle, index) => vehicle.id === vehicleId)
        ?.device?.id
    if (device) setDeviceId(device)
  }, [vehicleStatus])

  useEffect(() => {
    if (props.map) changeMapStyle(props.map, props.mapstyle)
  }, [props.mapstyle])

  const changeMapStyle = (map: google.maps.Map, style: string) => {
    map?.setOptions({ styles: styles[style] })
  }

  //CENTERING MAP
  useEffect(() => {
    //setMapZoom(props.center)
    if (livegeolocationsstatus === 'succeeded') fitall()

    if (props.map) {
      //map.panTo(props.center)
      const latlongi = new google.maps.LatLng(
        props.center.lat,
        props.center.lng,
      )
      //  smoothlyAnimatePanTo(map, latlongi)
    }
  }, [props.center])

  //hisotry drawer
  useEffect(() => {
    if (location.pathname === '/GA' && startD && endD) setopenRightDrawer(true)
    setRightDrawerMode('history')

    return () => {
      setOpenHistoryDrawer(false)
    }
  }, [])

  useEffect(() => {
    const isMobile = window.innerWidth <= 896
    setIsMobile(isMobile)
  }, [window.innerWidth])

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const HistoryClick = async (deviceid: string) => {
    setDeviceId(deviceid)

    setRightDrawerMode('history')
    setopenRightDrawer(true)
  }

  const InfoClick = (e: string) => {
    setDeviceId(e)

    setRightDrawerMode('liveinfo')
    setopenRightDrawer(true)
  }
  const CloseHistory = () => {
    // removePolylines()
    setopenRightDrawer(false)
  }

  //******set  this can be moved inside vehicle controller

  const handleCardClick = (deviceid: string) => {
    const item = deviceid
    let center: google.maps.LatLngLiteral
    const selected = livegeolocations.find(
      (dev: any) => dev.deviceUniqueId === deviceid,
    )
    console.log(livegeolocations)
    console.log(deviceid)
    const lat = selected?.latitude
    const lng = selected?.longitude

    if (lat && lng)
      center = {
        lat,
        lng,
      }
    else
      center = {
        lat: 0,
        lng: 0,
      }
    props.map?.setZoom(18)
    props.map?.setCenter(center)
    setDeviceId(deviceid)
    // setRightDrawerMode('liveinfo')
    //   setopenRightDrawer(true)
  }

  const fitall = () => {
    //based on zoom
    const zoomcenter = calculateMapCenterAndZoom(livegeolocations)

    //Hide MapController
    setIsCollapsed(true)

    props.map?.setZoom(zoomcenter.zoom)
    props.map?.setCenter({
      lat: zoomcenter.center.lat,
      lng: zoomcenter.center.lng,
    })
  }

  /**--------------------------------------------------------------------------------*/
  /** MAIN VIEW RETURN HERE                                                          */
  /**--------------------------------------------------------------------------------*/
  return (
    <>
      {!isMobile ? (
        <div>
          <div
            style={{
              position: 'fixed',
              right: '10px',
              top: '250px',
              zIndex: 1,
            }}
          >
            <span
              onClick={fitall}
              className="material-symbols-outlined"
              style={{ fontSize: '40px', color: '#1976d2', cursor: 'pointer' }}
            >
              fit_screen
            </span>
          </div>
          <div>
            <Tabs
              activeKey={selectedTab}
              onSelect={(eventKey) => {
                if (eventKey) setSelectedTab(eventKey)
              }}
            >
              <Tab eventKey="Vehicles" title="Vehicles">
                <VehiclesControllers
                  onclickHandler={handleCardClick}
                  historyClick={HistoryClick}
                  infoClick={InfoClick}
                  selectedTab={selectedTab}
                />
              </Tab>
              {!isMobile && (
                <Tab eventKey="Geofences" title="Geofences">
                  <GeofencesController
                    {...props}
                    map={props.map}
                    selectedTab={selectedTab}
                  />
                </Tab>
              )}
            </Tabs>
            {openRightDrawer &&
              historydeviceID != '' &&
              rightDrawerMode === 'history' && (
                <RightDrawer
                  close={() => {
                    setopenRightDrawer(false)
                    setRightDrawerMode('')
                  }}
                  anchor="right"
                >
                  <VehicleHistoryController
                    handleCloseButton={CloseHistory}
                    endDate={endDate}
                    startDate={startDate}
                    device={historydeviceID}
                    map={props.map}
                  />
                </RightDrawer>
              )}

            {openRightDrawer && rightDrawerMode === 'liveinfo' && (
              <VehicleInfo
                deviceid={historydeviceID}
                close={() => {
                  setopenRightDrawer(false)
                  setRightDrawerMode('')
                }}
              />
            )}

            {/* fuck me !!!!  this props go undefined sometimes. Advice to become a jedi in react and comeback to this! */}
            {props.map && vehicleStatus === 'succeeded' && (
              <MapLiveMarkerController map={props.map} />
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              position: 'fixed',
              right: '10px',
              top: '250px',
              zIndex: 1,
            }}
          >
            <span
              onClick={fitall}
              className="material-symbols-outlined"
              style={{ fontSize: '40px', color: '#1976d2', cursor: 'pointer' }}
            >
              fit_screen
            </span>
          </div>
          <CustomSwipeableDrawer
            content={
              <VehiclesControllers
                onclickHandler={handleCardClick}
                historyClick={HistoryClick}
                infoClick={InfoClick}
                selectedTab={selectedTab}
              />
            }
          />
          {openRightDrawer &&
            historydeviceID != '' &&
            rightDrawerMode === 'history' && (
              <RightDrawer
                close={() => {
                  setopenRightDrawer(false)
                  setRightDrawerMode('')
                }}
                anchor="right"
              >
                <VehicleHistoryController
                  handleCloseButton={CloseHistory}
                  endDate={endDate}
                  startDate={startDate}
                  device={historydeviceID}
                  map={props.map}
                />
              </RightDrawer>
            )}

          {openRightDrawer && rightDrawerMode === 'liveinfo' && (
            <VehicleInfo
              deviceid={historydeviceID}
              close={() => {
                setopenRightDrawer(false)
                setRightDrawerMode('')
              }}
            />
          )}
          {/* fuck me !!!!  this props go undefined sometimes. Advice to become a jedi in react and comeback to this! */}
          {props.map && vehicleStatus === 'succeeded' && (
            <MapLiveMarkerController map={props.map} />
          )}
        </>
      )}
    </>
  )
}

export default MapController
