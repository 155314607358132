import axios from 'axios'
import { Dict, LastKnownData } from './types'
import { URL } from './urls'

const BASE_URL = URL.URl_ALL

const f = (r: any): Dict => JSON.parse(r)

export const getGeoLocations = async (
  organizationId: string,
  deviceId: string | null | undefined,
  startDate: Date | null,
  endDate: Date | null,
  sessionToken: string,
): Promise<Dict> => {
  //
  //

  const response = await axios.get<any>(`${BASE_URL}/geolocations`, {
    params: { startDate, endDate, deviceId, organizationId },

    transformResponse: f,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  })

  //
  return response.data['result']
}

export const getLatestGeoLocations = async (
  organizationId: string | null,
  sessionToken: string,
): Promise<LastKnownData[]> => {
  const response = await axios.get<any>(`${BASE_URL}/latestDeviceData/`, {
    params: { organizationId },

    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  })
  const transformedItems = response.data.map((item: any) => ({
    ...item,
    latitude: parseFloat(item.latitude),
    longitude: parseFloat(item.longitude),
  }))
  console.log(transformedItems)
  //
  //return response.data['Items']
  return transformedItems
}

export const getDevicesLatestGeoLocations = async (
  deviceId: string,
  organizationId: string,
  sessionToken: string,
  startDate?: Date,
  endDate?: Date,
  retrieveDeviceData?: boolean,
  retrieveGeolocationData?: boolean | null,
  limit?: number,
  ascendingOrder?: boolean,
): Promise<any[]> => {
  const response = await axios.get<any>(`${BASE_URL}/deviceData/${deviceId}`, {
    params: {
      startDate,
      endDate,
      organizationId,
      retrieveDeviceData,
      retrieveGeolocationData,
      limit,
      ascendingOrder,
    },

    transformResponse: f,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  })

  //
  return response.data
}
