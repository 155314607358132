import Typography from '@mui/material/Typography'
import '../styles/index.css'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

import Gauge from './gauges/Gauge'
import FuelGauge from './gauges/FuelGauge'
import { Vehicle } from '../api/types'
import { selectAllVehicles } from '../features/vehicles/vehicleSlice'
import useWindowSize from '../customHooks/useWindowSize'
import { Button } from '@mui/material'
import { relative } from 'path'
//import Speedometer from './gauges/Speedometer'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export type vehicInfoprops = {
  deviceid: string
  close?: () => void
}

export interface sharedVehicle {
  name: string
  vehicle: { id: string | undefined }
  startDate: Date
  endDate: Date
}

export const VehicleInfo = (props: vehicInfoprops) => {
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )

  const speedUnits = useSelector(
    (state: RootState) => state.usersettings?.usersettings?.speedUnit,
  )

  const vehicles = useSelector(selectAllVehicles)
  //TODO

  return (
    <>
      {'this is the semi transparent background panel '}
      <div
        style={{
          width: 300,
          height: useWindowSize().height,
          backgroundColor: 'white',
          opacity: 0.5,
          position: 'absolute',
          zIndex: 2,
          top: 60,
          left: '85%',
        }}
      ></div>
      <Button
        onClick={props.close}
        variant={'contained'}
        style={{
          margin: 10,
          top: 70,
          right: -20,
          width: 30,
          position: 'relative',
          color: 'red',

          borderRadius: 15,
          border: 'none',
        }}
      >
        X
      </Button>
      <div
        style={{
          position: 'absolute',
          zIndex: 101,
          top: 60,
          left: '85%',
        }}
      >
        <div>
          <h3>
            {
              vehicles.find((item, index) => item.device?.id === props.deviceid)
                ?.name!
            }
          </h3>
        </div>
        <Gauge
          gaugeSize={200}
          maxValue={140}
          //TODO
          // speed={
          //   speedUnits === 'mile'
          //     ? livegeolocations[props.deviceid][0].speed || 0 / 1.6
          //     : livegeolocations[props.deviceid][0].speed || 0
          // }
          speed={1}
          scale={
            speedUnits
              ? speedUnits === 'mile'
                ? 'mph'
                : 'kmh' || 'unk'
              : 'mile'
          }
          odometer={105500}
        />
        <FuelGauge gaugeSize={150} maxValue={90} fuel={50} scale={'%'} />
        <label style={{ fontSize: 30 }}>ODO : </label>
        <label style={{ fontFamily: 'SevenSegment', fontSize: 30 }}>
          {100400}
        </label>
        <Typography>Iginition Status</Typography>
      </div>
    </>
  )
}
