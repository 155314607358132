import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { getGeoLocations } from '../../api/geoLocation'
import { Dict, DictData, secureCompany } from '../../api/types'
import { getDeviceData } from '../../api/devdata'

let devicedata: any[] = []

export type ddhunkprops = {
  company: secureCompany
  deviceID: string
  startDate?: Date
  endDate?: Date
  retrieveDeviceData?: boolean
  retrieveGeolocationData?: boolean | null
  limit?: number
  ascendingOrder?: boolean
}

let initialState = {
  devicedata,
  status: 'idle',
  error: '',
}

export const fetchDeviceData = createAsyncThunk(
  'devicedata/fetchDeviceData',
  async (obj: ddhunkprops) => {
    const result = await getDeviceData(
      obj.deviceID,
      obj.company.organizationId,
      obj.company.token,
      obj?.startDate,
      obj?.endDate,
      obj?.retrieveDeviceData,
      obj?.retrieveGeolocationData,
      obj?.limit,
    )
    return result
  },
)

const deviceDataSlice = createSlice({
  name: 'devicedataslice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDeviceData.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchDeviceData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.devicedata = action.payload
      })
      .addCase(fetchDeviceData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'devicedata  could not be retrieved'
        //TODO  state.error = action.error.message
        //
      })
  },
})

export default deviceDataSlice.reducer

export const selectAllDeviceData = (state: { devicedata: { geo: Dict } }) =>
  state.devicedata
