import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { secureUserCompany, User, UserSettings } from '../../api/types'
import { getUserSettings, updateUserSettings } from '../../api/usersettings'
import { secureUserSettings } from '../../components/UserPreferences'

let initalSettings: UserSettings = {}

let initialState = {
  usersettings: initalSettings,
  status: 'idle',
  error: '',
}

export const fetchUserSettings = createAsyncThunk(
  'users/fetchUserSettings',

  async (obj: secureUserCompany) => {
    console.log('hereee', obj.userId)
    const response = await getUserSettings(
      obj.organizationId,
      obj.userId,
      obj.token,
    )

    return response
  },
)

export const updUserSettings = createAsyncThunk(
  'users/updUserSettings',

  async (obj: secureUserSettings) => {
    const response = await updateUserSettings(
      obj.organizationId,
      obj.userSet,
      obj.token,
    )
    return obj
  },
)

const userSettingsSlice = createSlice({
  name: 'usersettings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserSettings.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        state.status = 'succeeded'

        //  state.usersettings = action.payload
        state.usersettings = action.payload
        //  console.log(action.payload)
      })
      .addCase(fetchUserSettings.rejected, (state, action) => {
        state.status = 'failed'
      })

      .addCase(updUserSettings.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updUserSettings.fulfilled, (state, action) => {
        state.status = 'succeeded'

        state.usersettings = action.payload.userSet
        alert('user setings updated correctly')
      })
      .addCase(updUserSettings.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export default userSettingsSlice.reducer

export const selectAllUserSettings = (state: {
  usersettings: { settings: UserSettings }
}) => state.usersettings
