import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useWindowSize from '../customHooks/useWindowSize'
import { selectAllVehicles } from '../features/vehicles/vehicleSlice'
import { DateRangeProps } from '../api/types'
import {
  Button,
  Card,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import { getLocalDate } from './VehicleHistoryController'

function DateRangeSelector(props: DateRangeProps) {
  let start = new Date()
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)

  let end = new Date()
  end.setHours(23)
  end.setMinutes(59)
  const [startDate, setStartDate] = useState<Date>(start)
  const [endDate, setEndDate] = useState<Date>(end)

  return (
    <>
    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"10px",flexDirection:"column"}}>

      <div style={{marginBottom:"10px"}}>
        <TextField
          size="small"
          id="StartDate"
          value={getLocalDate(startDate)}
          type="datetime-local"
          style={{
            width: "100%"
          }}
          onChange={(e) => {
            const startdate = new Date(e.target.value)
            setStartDate(startdate)
          }}
        />
      </div>
      <div>
        <TextField
          value={getLocalDate(endDate)}
          size="small"
          id="EndDate"
          type="datetime-local"
          style={{ width: "100%" }}
          onChange={(e) => {
            const enddate = new Date(e.target.value)
            setEndDate(enddate)
          }}
        />
      </div>
    </div>
      <div style={{display:"flex",justifyContent:"flex-start"}}>

      <Button
        size="small"
        variant="contained"
        onClick={async () => {
          const range = endDate.getDate() - startDate.getDate()
          if (range <= 7) props.handleUpdateButton({ startDate, endDate })
          else alert('Please select a Date Window no bigger than a week')
        }}
      >
        {props.ButtonString}
      </Button>
      </div>
    </>
  )
}

export default DateRangeSelector
