import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { Vehicle } from '../api/types'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
export type GeoAccessListProps = {
  map?: google.maps.Map
}

function MapLiveMarkerController(props: GeoAccessListProps) {
  const livegeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )
  const vehicles = useSelector((state: RootState) => state.vehicles.vehicles) // Adjust according to your state structure

  // Using an object to store marker references, keyed by deviceUniqueId
  const markerRefs = useRef<any>({})
  const markerClustererRef = useRef<MarkerClusterer | null>(null)

  const animateMarker = (
    marker: google.maps.Marker,
    newPosition: google.maps.LatLng,
  ) => {
    const startPosition = marker.getPosition()
    let deltaLat = 0
    let deltaLng = 0
    let step = 0

    if (startPosition) {
      deltaLat = (newPosition.lat() - startPosition.lat()) / 100
      deltaLng = (newPosition.lng() - startPosition.lng()) / 100

      const moveMarker = () => {
        const lat = startPosition!.lat() + deltaLat * step
        const lng = startPosition!.lng() + deltaLng * step
        marker.setPosition(new google.maps.LatLng(lat, lng))
        if (step < 100) {
          step++
          requestAnimationFrame(moveMarker)
        }
      }

      moveMarker()
    }
  }

  useEffect(() => {
    // Initialize or update markers based on livegeolocations

    const markerArray = livegeolocations.map((geo) => {
      let marker = markerRefs.current[geo.deviceUniqueId]
      const color = vehicles.find(
        (vehic: Vehicle) => vehic?.device?.id === geo.deviceUniqueId,
      )?.pathColor

      if (!marker && props.map) {
        const markerSymbol: google.maps.Symbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          rotation: geo.heading,
          scale: 5,
          fillColor: color,
          strokeColor: color,
          strokeWeight: 3,
        }
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(geo.latitude, geo.longitude),
          map: props.map,
          icon: markerSymbol,
        })
        markerRefs.current[geo.deviceUniqueId] = marker
      } else if (marker) {
        const newPosition = new google.maps.LatLng(geo.latitude, geo.longitude)
        animateMarker(marker, newPosition)
      }
      return marker
    })

    //this should be improved.Clusterer is not going to be updated when markers are updated
    if (!markerClustererRef.current) {
      markerClustererRef.current = new MarkerClusterer({
        markers: Object.values(markerRefs.current),
        map: props.map,
      })
    }
  }, [livegeolocations, props.map])

  return <div></div>
}

export default MapLiveMarkerController
