import React, { useEffect, useState } from 'react'
import { timezones } from '../assets/timezones'

type timezone = {
  zone: string
  area: string
}

const Timezonepicker = () => {
  let zonearray: timezone[] = []

  for (const [index, [key, value]] of Object.entries(
    Object.entries(timezones),
  )) {
    const temptz: timezone = { zone: key, area: value }

    zonearray.push(temptz)
  }

  return (
    <div>
      <select>
        {zonearray.map((zone: timezone, index: number) => {
          return (
            <option key={index} value={zone.zone}>
              {zone.area}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Timezonepicker
