// src/Carousel.js
import React, { useEffect, useState } from "react";
import "./PhoneCarousel.css";

type PhoneCarouselProps = {autoPlay:any, interval:any}
const phoneCarouselImages = [
<img className="phone_carousel_image" src='app_sample_screen_1.png'></img>,
<img className="phone_carousel_image" src='app_sample_screen_2.png'></img>
];
const PhoneCarousel = ({autoPlay,interval}:PhoneCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    let intervalId:any;

    if (autoPlay) {
      intervalId = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % phoneCarouselImages.length);
      }, interval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoPlay, interval, phoneCarouselImages]);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + phoneCarouselImages.length) % phoneCarouselImages.length);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % phoneCarouselImages.length);
  };

  return (
    <div className="carousel">
    <div className="phone_carousel--phone_frame_container">
        <img className="phone_carousel--phone_frame_image" src="phoneFrame.png" alt="" />
    </div>
      <button onClick={handlePrev}>
        <span className="material-icons">arrow_left</span>
      </button>
      <div>{phoneCarouselImages[activeIndex]}</div>
      <button onClick={handleNext}>
        <span className="material-icons">arrow_right</span>
      </button>
    </div>
  );
};

export default PhoneCarousel;
