import { Fragment, ReactElement } from 'react'

import { useState, useEffect } from 'react'
import '../styles/tracking-styles.css'

import { Wrapper, Status } from '@googlemaps/react-wrapper'

import { MapProps, secureCompany, secureUserCompany } from '../api/types'

import { fetchVehicles } from '../features/vehicles/vehicleSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { useAppDispatch } from '../app/hooks'
import { MainMap } from './MainMap'
import { fetchLiveGeolocations } from '../features/geolocations/geolocationSlice'

import { fetchDrivers } from '../features/drivers/driverSlice'

import { fetchDevices } from '../features/devices/deviceSlice'

import { CircularProgress } from '@mui/material'
import { userLogout } from '../features/session/cogAccount'
import { deauthenticate } from '../features/session/sessionSlice'
import { fetchUserSettings } from '../features/usersSettings/usersettingsSlice'

//must be global otherwise is reloaded everytime with the component and never would stop the function
let xIntervId: any

const render = (status: Status): ReactElement => {
  return <h1>{status}</h1>
}

const MainMapContainer = (props: { polling: boolean }) => {
  //---inital state of date range do not erase

  //---
  const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY
  // const REACT_APP_API_KEY =    'AIzaSyBgElOLY6RBv6aMX57jlhTOC8AxVEFfDiQ&libraries=drawing'

  const timeout = 10000
  const stoptimeout = 3600000 / timeout //avoid api refresh after 1 hour this needs to be done liken netflix
  let timercount = 0
  const [mapCenter, setmapCenter] = useState<google.maps.LatLngLiteral>({
    lat: 26.25979,
    lng: -80.08094,
  })

  const dispatch = useAppDispatch()

  const livegeolocationstatus = useSelector((state: RootState) => state.geolocations.status)

  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  const driverStatus = useSelector((state: RootState) => state.drivers.status)
  const devicesStatus = useSelector((state: RootState) => state.devices.status)
  const [firstload, setfirstload] = useState<null | boolean>(null)
  const sessionToken = useSelector((state: RootState) => state.session.userdata.accesstoken)

  const organizationId = useSelector((state: RootState) => state.session.userdata.organizationId)

  const userId = useSelector((state: RootState) => state.session.userdata.userinfo[0].userId)
  const usersettings = useSelector((state: RootState) => state.usersettings.usersettings)

  const tokenexpDate = useSelector((state: RootState) => state.session.userdata.idtoken['exp'])
  const fetchUserSets = async (secUser: secureUserCompany) => {
    await dispatch(fetchUserSettings(secUser))
  }

  useEffect(() => {
    if (vehicleStatus === 'idle') {
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId,
      }

      dispatch(fetchVehicles(secCompany))

      if (driverStatus === 'idle') {
        dispatch(fetchDrivers(secCompany))
      }

      if (devicesStatus === 'idle') {
        dispatch(fetchDevices(secCompany))
      }
    }
  }, [vehicleStatus])

  useEffect(() => {
    const secUsCompany: secureUserCompany = {
      token: sessionToken,
      organizationId: organizationId,
      userId: userId,
    }

    // if (usersettingsstatus === 'idle') {
    fetchUserSets(secUsCompany)
    // }
  }, [])

  let zoom
  let centerp: google.maps.LatLngLiteral
  centerp = mapCenter
  zoom = 3
  useEffect(() => {
    if (!firstload) {
      centerp = mapCenter
      setfirstload(true)
    }
  }, [livegeolocationstatus, dispatch])

  useEffect(() => {
    console.log('first  call')
    getLiveData()

    //(' will run every timeout seconds!'
    if (props.polling) {
      console.log(' running on periodic timer', props.polling)
      xIntervId = setInterval(getLiveData, timeout)
      return () => clearInterval(xIntervId)
    } else {
      console.log(' running on Web Socket')
      return undefined
    }
  }, [props.polling])

  const getLiveData = async () => {
    console.log('api pollling')
    const secCompany: secureCompany = {
      token: sessionToken,
      organizationId: organizationId,
    }
    //stopping automatically if user keeps pulling data because left window open, change this to a warning like netflix's are you still watching
    await dispatch(fetchLiveGeolocations(secCompany))
    if (timercount == stoptimeout) {
      clearInterval(xIntervId)
      xIntervId = null
    }
    check_iftoken_expired(tokenexpDate)
  }
  const LogoutandRedirect = () => {
    userLogout()
    dispatch(deauthenticate('test')) // TODO  good opractice to clean the whole redux store..!
  }

  const check_iftoken_expired = (tokenexpDate: number) => {
    const currentTimestamp = Math.floor(Date.now() / 1000)
    // console.log(tokenexpDate)

    if (tokenexpDate < currentTimestamp) {
      console.log('tokenExpired')
      LogoutandRedirect()
    }
  }

  let rmstyle = ''
  if (usersettings?.mapOverlay) rmstyle = usersettings.mapOverlay
  else rmstyle = 'default'
  const mapProps: MapProps = {
    center: centerp,
    zoom: zoom,
    height: '90vh',
    width: '100%',
    //TODO if no data is coming could crash substitute by ? in the future
    cssstyle: 'map',
    mapstyle: rmstyle,
    markerClicked: () => {}, //TODO remove
    enable_controller: true,
  }
  return (
    <Fragment>
      {REACT_APP_API_KEY && (
        <Wrapper apiKey={REACT_APP_API_KEY} render={render}>
          {firstload ? <MainMap {...mapProps}>map</MainMap> : <CircularProgress />}
        </Wrapper>
      )}
    </Fragment>
  )
}
export default MainMapContainer
