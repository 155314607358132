import { Geofence } from './types'
import { URL } from './urls'
import axios from 'axios'

const BASE_URL = URL.URl_ALL

export type Why = {
  [bodyitem: string]: []
}

export const getGeofences = async (
  organizationId: string,
  sessionToken: string,
): Promise<Geofence[]> => {
  return new Promise((res) => {
    res(
      axios
        .get<any>(`${BASE_URL}/geofence`, {
          //Todo yerba con los types porque todo esta metido dentro de un result
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data['result']
        }),
    )
  })
}

export const getGeofencesVehicles = async (
  geofenceid: string,
  organizationId: string,
  sessionToken: string,
): Promise<any> => {
  return new Promise((res) => {
    res(
      axios
        .get<any>(`${BASE_URL}/geofenceVehicles/${geofenceid}`, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data['result']
        }),
    )
  })
}

//TODO geofence alert type, geomet
export const addGeofence = async (
  geofence: Geofence,
  organizationId: string,
  sessionToken: string,
): Promise<any> => {
  return new Promise((res) => {
    res(
      axios
        .post<Geofence>(`${BASE_URL}/geofence`, geofence, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        }),
    )
  })
}

export const updateGeofence = async (
  geofence: Geofence,
  organizationId: string,
  sessionToken: string,
): Promise<Geofence> => {
  return new Promise((res) => {
    res(
      axios
        .patch<Geofence>(`${BASE_URL}/geofence/${geofence.id}`, geofence, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        }),
    )
  })
}

export const updateGeofenceVehicles = async (
  data: any,
  geofenceid: string,
  organizationId: string,
  sessionToken: string,
) => {
  return new Promise((res) => {
    res(
      axios
        .patch(`${BASE_URL}/geofenceVehicles/${geofenceid}`, data, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        }),
    )
  })
}

const f = (r: any): any => JSON.parse(r)

export const getGeoAccess = async (
  organizationId: string,
  startDate: Date | null,
  endDate: Date | null,
  sessionToken: string,
): Promise<any> => {
  //
  //

  const response = await axios.get<any>(`${BASE_URL}/geofenceAccess`, {
    params: { startDate, endDate, organizationId },

    transformResponse: f,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  })

  //
  return response.data['result']
}

// {{baseURL}}/geofenceVehicles/78af21e5-c32b-76ff-709e-8e6a95f32dc5?organizationId=7b7bc89e-aaaf-bcd5-d9cf-bed7b53f14dc
// https://n1vcfb0mu5.execute-api.us-east-1.amazonaws.com/geofenceVehicles/?organizationId=7b7bc89e-aaaf-bcd5-d9cf-bed7b53f14dc
