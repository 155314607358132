// Gauge.tsx

import React from 'react'
import './Gauge.css'

interface GaugeProps {
  speed: number
  maxValue: number
  gaugeSize: number
  scale: string
  odometer: number
}

const Gauge = (props: GaugeProps) => {
  const rotateValue = (props.speed / props.maxValue) * 180 - 90 // Assumes maximum speed is 220, adjust as needed

  const renderTicks = () => {
    const ticks = []
    const numTicks = 8 // Change this value to adjust the number of ticks
    const tickDistance = 90 // Distance of the ticks from the center of the gauge

    for (let i = 0; i < numTicks; i++) {
      const tickRest = ((i * props.maxValue) / (numTicks - 1)) % 10
      const tickValue = (i * props.maxValue) / (numTicks - 1) - tickRest
      const tickRotation = (tickValue / props.maxValue) * 180 - 90
      const tickPositionX =
        props.gaugeSize / 2 +
        tickDistance * Math.cos((tickRotation - 90) * (Math.PI / 180))
      const tickPositionY =
        props.gaugeSize / 2 +
        tickDistance * Math.sin((tickRotation - 90) * (Math.PI / 180))

      ticks.push(
        <div
          key={i}
          className="gauge__tick"
          style={{
            left: tickPositionX,
            top: tickPositionY,
            transform: `rotate(${tickRotation}deg)`,
          }}
        >
          <label style={{ position: 'absolute', top: 6, left: -5 }}>
            {' '}
            {tickValue.toFixed()}{' '}
          </label>
        </div>,
      )
    }
    return ticks
  }

  return (
    // <div className="gauge-container">
    //   <div className="gauge">
    //     <div
    //       className="gauge__needle"
    //       style={{ transform: `rotate(${rotateValue}deg)` }}
    //     />
    //     <div className="gauge__speed">{props.speed} mph</div>
    //   </div>
    // </div>

    <div className="gauge-container">
      <div
        className="gauge"
        style={{ height: props.gaugeSize, width: props.gaugeSize }}
      >
        {/* Render the gauge ticks */}
        {renderTicks()}
        <div
          className="gauge__needle"
          style={{ transform: `rotate(${rotateValue}deg)` }}
        />
        <div className="odometer">{props.odometer}</div>
        <div className="gauge__speed">
          {props.speed} {props.scale}
        </div>
      </div>
    </div>
  )
}

export default Gauge
