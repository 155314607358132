import React, { Fragment, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { Device, Driver, secureCompany, secureVehicle, Vehicle } from '../../api/types'
import { addNewVehicle, selectAllVehicles } from './vehicleSlice'

import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../app/hooks'
import { RootState } from '../../app/store'
import { Box, Button, Grid, Select, TextField, Typography } from '@mui/material'
import { fetchDrivers, selectAllDrivers } from '../drivers/driverSlice'
import { fetchDevices, selectAllDevices } from '../devices/deviceSlice'

export const AddVehicle = () => {
  const sessionToken = useSelector((state: RootState) => state.session.userdata.accesstoken)
  const organizationId = useSelector((state: RootState) => state.session.userdata.organizationId)
  const alldrivers = useSelector(selectAllDrivers)
  const alldevices = useSelector(selectAllDevices)
  const allvehicles = useSelector(selectAllVehicles)
  const [name, setName] = useState('')
  const [status, setStatus] = useState()
  const [device, setDevice] = useState<Device>()
  const [driver, setDriver] = useState<Driver>()
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [show, setShow] = useState(false)
  const deviceStatus = useSelector((state: RootState) => state.devices.status)
  const driverStatus = useSelector((state: RootState) => state.drivers.status)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (driverStatus === 'idle') {
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId,
      }
      dispatch(fetchDrivers(secCompany))
    }
    if (deviceStatus === 'idle') {
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId,
      }
      dispatch(fetchDevices(secCompany))
    }
  }, [])

  const onNameChanged = (e: { target: { value: React.SetStateAction<string> } }) => setName(e.target.value)

  const selectDevice = (e: any) => {
    e.preventDefault()

    if (e.target.value) {
      const device = alldevices.find((device: Device) => device.id === e.target.value)
      setDevice(device)
    } else {
      setDevice(undefined)
    }
  }

  const selectDriver = (e: any) => {
    e.preventDefault()

    if (e.target.value) {
      const usuario = alldrivers.find((driver: Driver) => driver.id === e.target.value)

      setDriver(usuario)
    } else {
      setDriver(undefined)
    }
  }

  const onCateogryChanged = (e: { target: { value: React.SetStateAction<string> } }) => setCategory(e.target.value)
  const onDescritpionChanged = (e: { target: { value: React.SetStateAction<string> } }) =>
    setDescription(e.target.value)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const canSave = [name].every(Boolean) && addRequestStatus === 'idle'

  const onSaveVehicleClicked = async () => {
    const newVehicle: Vehicle = {
      name: name,
      device: device,
      driver: driver,
      status: 'A',
      description: description,
      category: category,
    }
    const secVehicle: secureVehicle = {
      vehicle: newVehicle,
      organizationId: organizationId,
      token: sessionToken,
    }
    if (canSave) {
      try {
        setAddRequestStatus('pending')
        await dispatch(addNewVehicle(secVehicle)).unwrap()
        setName('')
        setDevice(undefined)
        setDriver(undefined)
        setDescription('')
      } catch (err) {
        console.error('Failed to save the post: ', err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
        {/* style={{ alignSelf: 'flex-end' }} */}
        <Button variant="contained" onClick={handleShow} sx={{ alignSelf: 'flex-end' }}>
          + Add Vehicle
        </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please enter vehicle info.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <TextField
              label="Vehicle Name"
              size="small"
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={onNameChanged}
            />

            <div style={{ marginTop: 10 }}>Devices Available</div>
            <select id="devices" onChange={selectDevice} style={{ minHeight: 35, borderRadius: 5 }}>
              <option selected={true}></option>
              {alldevices.map((device: Device, index: number) => {
                if (!allvehicles.find((vehic: Vehicle) => vehic.device?.id === device.id))
                  return (
                    <Fragment key={device.id}>
                      <option value={device.id}>{device.name}</option>
                    </Fragment>
                  )
                else return null
              })}
            </select>

            <div style={{ marginTop: 10 }}>Driver:</div>
            <select id="drivers" onChange={selectDriver} style={{ minHeight: 35, borderRadius: 5 }}>
              <option selected={true}></option>
              {alldrivers.map((driver: Device, index: number) => {
                return (
                  <Fragment key={index}>
                    <option value={driver.id}>{driver.name}</option>
                  </Fragment>
                )
              })}
            </select>

            <Typography style={{ marginTop: 10 }}>Description:</Typography>
            <TextField
              size="small"
              label="Description"
              type="text"
              id="description"
              name="descripition"
              value={description}
              onChange={onDescritpionChanged}
            />
            <div>
              <Button variant="contained" onClick={onSaveVehicleClicked} sx={{ marginTop: 1 }}>
                Save Vehicle
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
