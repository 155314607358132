import { useState } from 'react'
import SessionLogin from '../../../features/session/SessionLogin'

import Button from '@mui/material/Button'
import PersonIcon from '@mui/icons-material/Person'
import { useSelector } from 'react-redux'
import './LandingPageLogin.css'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { useNavigate } from 'react-router'

type prfmenprops = {
  status: boolean
}

export default function UserProfileMenu(props: prfmenprops) {
  const navigate = useNavigate()
  const [openLoginDialog, setOpenLoginDialog] = useState<boolean>(false)
  const handleClick = () => {
    navigate('/login')
  }
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)

  const handleClickOutsideMenu = () => {
    setOpenLoginDialog(false)
  }
  const ref: any = useOutsideClick(handleClickOutsideMenu)
  return (
    <div ref={ref} className="landing_page_login--main_container">
      <Button
        sx={{ minWidth: 20, padding: '5px' }}
        variant="text"
        startIcon={<PersonIcon style={{ fontSize: '30px' }} />}
        onClick={handleClick}
        size="large"
        {...(themeName === 'light'
          ? { style: { color: 'black' } }
          : { style: { color: 'white' } })}
        className="header_section--menu_bar--button"
      />
      <div
        {...(openLoginDialog === true
          ? { className: 'landing_page_login--login_scree_main_container' }
          : {
              className:
                'landing_page_login--login_scree_main_container_hidden',
            })}
      >
        <SessionLogin />
      </div>
    </div>
  )
}
