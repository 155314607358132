// import React, { useState } from 'react'
// import Battery from '../components/gauges/battery'
// import Speedometer from '../components/gauges/Speedometer'
// import Gauge from '../components/gauges/Gauge'

// import { CircularProgress } from '@mui/material'

// import DeviceDataGrid from '../features/deviceData/DeviceDataGrid'
// import HOSGraph from './HOSGRaph'

// import ReactDOM from 'react-dom'
// import PieChart from '../charts/PieChart'
// import ScatterChart from '../charts/ScatterChart'
// import StateBoundaries from '../components/StateBoundaries'

// //import WS_all from '../components/WS_all'
// const data = [
//   { label: 'Category 1', value: 25, color: 'blue' },
//   { label: 'Category 2', value: 40, color: 'green' },
//   { label: 'Category 3', value: 20, color: 'red' },
//   { label: 'Category 4', value: 10, color: 'orange' },
//   { label: 'Category 5', value: 5, color: 'purple' },
// ]

// const data2 = [
//   { x: 1, y: 1, color: 'blue' },
//   { x: 10, y: 10, color: 'blue' },
//   { x: 30, y: 30, color: 'green' },
//   { x: 40, y: 40, color: 'red' },
//   // Add more data points as needed
// ]

// const  TestTouch=()=> {

// const [touchStart, setTouchStart] = useState(null)
// const [touchEnd, setTouchEnd] = useState(null)

// // the required distance between touchStart and touchEnd to be detected as a swipe
// const minSwipeDistance = 50

// const onTouchStart = (e) => {
//   setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
//   setTouchStart(e.targetTouches[0].clientX)
// }

// const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

// const onTouchEnd = () => {
//   if (!touchStart || !touchEnd) return
//   const distance = touchStart - touchEnd
//   const isLeftSwipe = distance > minSwipeDistance
//   const isRightSwipe = distance < -minSwipeDistance
//   if (isLeftSwipe || isRightSwipe)
//     console.log('swipe', isLeftSwipe ? 'left' : 'right')
//   // add your conditional logic here
// }

// }

function TestPage() {
  return (
    <>
      {/* <HOSGraph />
      <DeviceDataGrid device_id="453c05de-cd54-6897-569d-d030a59c7d5d" />
      <Battery percentage={50} />
      <Speedometer maxValue={140} value={50} size={80} />
      <CircularProgress /> */}
      {/* <Gauge speed={140} maxValue={140} gaugeSize={100} /> */}
      {/* <PieChart width={200} height={200} data={data} /> */}
      {/* <WebSocketEx /> */}
      {/* <ScatterChart data={data2} width={400} height={300} margin={0} /> */}
      {/* <StateBoundaries /> */}
      {/* <WS_all /> */}
    </>
  )
}

export default TestPage
