import {
  ICognitoUserPoolData,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'

const poolData: ICognitoUserPoolData = {
  //btusers
  UserPoolId: 'us-east-1_Mi7joed4h',
  ClientId: '6kcg0kpuh6u736vtfh8i2mibp6',
  // //users
  //   UserPoolId: 'us-east-1_ZtftfKzp3',
  //   ClientId: '6pg840b22gb5baq48883d4j44d',
}

export default new CognitoUserPool(poolData)
