import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useSelector } from 'react-redux';
import "./ProductsMenu.css"

type ProductsMenuProps = {
  autoOpen:boolean;
}
export default function ProductsMenu({autoOpen}:ProductsMenuProps) {
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMouseEnter = (event:any)=>{
    setAnchorEl(event.currentTarget)
  }

  const handleMouseLeave = (event:any)=>{
    setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTrackingMenuClick = () => {
    setAnchorEl(null);
  };

  const handleLogbookMenuClick = () => {
    setAnchorEl(null);
  };

  const handleDispatchingMenuClick = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        endIcon={open?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>}
        id="products-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
            className="header_section--menu_bar--button"
            {...(themeName==="light"?{style:{color:'black',fontFamily: 'Montserrat'}}:{style:{color:'white',fontFamily: 'Montserrat'}})}
      >
        Products
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'products-button',
        }}
      >
        <MenuItem onClick={handleTrackingMenuClick} className='ProductsMenu_Menu_Item'>Tracking</MenuItem>
        <MenuItem onClick={handleLogbookMenuClick} className='ProductsMenu_Menu_Item'>Logbook</MenuItem>
        <MenuItem onClick={handleDispatchingMenuClick} className='ProductsMenu_Menu_Item'>Dispatching</MenuItem>
      </Menu>
    </div>
  );
}