import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js'
import { RdxStateSession, UsersInfo } from '../../api/types'
import { getSession } from './cogAccount'

let emptydata: RdxStateSession = {
  username: '',
  accesstoken: '',
  idtoken: {},
  userinfo: [],

  organizationId: '',
  role: [],
}

let initialState = {
  userdata: emptydata,
  status: 'idle',
  error: null as string | null,
}

export const fetchsessionthunk = createAsyncThunk(
  'session/fetchSession',

  async () => {
    let sessionUserdata: RdxStateSession = emptydata

    await getSession()
      .then((data: any) => {
        const user: CognitoUser = data['user']

        const session: CognitoUserSession = data['session']

        const idToken = session.getIdToken().decodePayload()

        const userinfo: UsersInfo[] = JSON.parse(idToken['users'])

        sessionUserdata = {
          coguser: user,
          username: data['user']['username'],
          accesstoken: session.getAccessToken().getJwtToken(),
          idtoken: idToken,
          userinfo: userinfo,
          profile: data['profile'],
          organizationId: localStorage.getItem('organizationId')!,
          role: JSON.parse(localStorage.getItem('role')!),
        }
      })
      .catch((e: any) => {
        console.log('error', e)
      })

    // response.forEach((element) => {

    // })

    return sessionUserdata
  },
)

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      const userdata = action.payload
      state.userdata = userdata
      state.status = 'succeeded'
    },
    deauthenticate: (state, action) => {
      //action.type is equal to the reducer name in this case sesssion/deauthenticate
      state.userdata = emptydata
      state.status = 'idle'
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchsessionthunk.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchsessionthunk.fulfilled, (state, action) => {
        state.status = 'succeeded'

        state.userdata = action.payload
      })
      .addCase(fetchsessionthunk.rejected, (state, action) => {
        state.status = 'failed'

        //
      })
  },
})

export const { setCredentials, deauthenticate } = sessionSlice.actions

export default sessionSlice.reducer

export const selectSession = (state: any) => state.session
