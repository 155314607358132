import React from 'react'
import Dashboard from './MaterialTemplates/Dashboard'
function DashboardPage() {
  return (
    <div>
      <Dashboard />
    </div>
  )
}

export default DashboardPage
