import * as React from 'react';
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { updateTheSelectedTheme } from '../../redux/actions/ThemeSelector';
import "./DarkLightModeSelector.css"

export default function DarkLightModeSelector() {
    const { themeName} =
    useSelector((state: any) => state.selectedThemeReducer);

    const dispatch:any = useDispatch();

    const handleModeSelectButton = ()=>{
        if(themeName==="light"){
            dispatch(updateTheSelectedTheme("dark"))
        }else{
            dispatch(updateTheSelectedTheme("light"))
        }
    }

    return(
        <div className='dark_light_mode_selector--main_container'>
            <Button sx={{minWidth:30,padding:"5px",marginRight:"5px"}} variant="text" onClick={handleModeSelectButton} color="primary" endIcon={themeName==="light"?<DarkModeIcon/>:<LightModeIcon/>}>
            </Button>
        </div>
    );
}