import React, { Fragment, useContext } from 'react'

import { useEffect, useState } from 'react'

import { HistoryControllerProps, secureCompany, Vehicle } from '../api/types'

import '../styles/HistoryList.css'
import { useSelector } from 'react-redux'

import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { DrawerContext } from '../App'

import useWindowSize from '../customHooks/useWindowSize'
import { RootState } from '../app/store'

import {
  fetchHistGeolocations,
  hthunkprops,
} from '../features/geolocations/histgeolocationSlice'
import { useAppDispatch } from '../app/hooks'

import HistoryTimeline from './VehicleHistoryTimeline'

import { selectAllVehicles } from '../features/vehicles/vehicleSlice'

const offset = new Date().getTimezoneOffset() * 1000 * 60

export const getLocalDate = (value: Date) => {
  const offsetDate = new Date(value).valueOf() - offset
  const date = new Date(offsetDate).toISOString()
  return date.substring(0, 16)
}
type RenderLines = {
  [key: string]: google.maps.Polyline | undefined
  //path_coordinates.push(mpoint)
}

let historyPolylines: RenderLines = {}

export const VehicleHistoryController = (props: HistoryControllerProps) => {
  const dispatch = useAppDispatch()
  const vehicles = useSelector(selectAllVehicles)
  const [startDate, setStartDate] = useState<Date>(props.startDate)
  const [endDate, setEndDate] = useState<Date>(props.endDate)
  const [marker, setmarker] = useState<google.maps.Marker>()
  const [selectCustom, setCustomDate] = useState<string>(
    props.endDate && props.startDate ? 'Custom' : 'Today',
  )

  const historygeolocationstatus = useSelector(
    (state: RootState) => state.histgeolocations.status,
  )
  const historygeolocations = useSelector(
    (state: RootState) => state.histgeolocations.histgeolocations,
  )

  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  useEffect(() => {
    if (historygeolocationstatus === 'idle') updateDates({ startDate, endDate })
  }, [historygeolocationstatus])

  useEffect(() => {
    removePolylines()
    if (historygeolocationstatus === 'succeeded') renderPolylines()

    return () => {
      removePolylines() // to remove when component is unrendered/closed
    }
  }, [historygeolocationstatus])

  const updateStart = async (difference: number) => {
    if (difference === 0) {
      let startDate = new Date()
      let endDate = new Date()

      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)

      setEndDate(endDate)
      setStartDate(startDate)
      updateDates({ startDate, endDate })
    }

    if (difference === 1) {
      let startDate = new Date()
      let endDate = new Date()

      startDate.setDate(startDate.getDate() - difference)
      endDate.setDate(endDate.getDate() - difference)

      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)

      setEndDate(endDate)
      setStartDate(startDate)
      updateDates({ startDate, endDate })
    }
  }

  useEffect(() => {
    updateDates({ startDate, endDate })
  }, [props.device])

  const ranges = ['Today', 'Yesterday', 'Custom']

  const selectRange = (e: any) => {
    setCustomDate(e.target.value)
    switch (e.target.value) {
      case 'Today':
        updateStart(0)
        break
      case 'Yesterday':
        updateStart(1)
        break

      default:
        break
    }
  }

  const updateDates = async (e: {
    startDate: Date | null
    endDate: Date | null
  }) => {
    const secCompany: secureCompany = {
      token: sessionToken,
      organizationId: organizationId,
    }
    const hprops: hthunkprops = {
      company: secCompany,
      deviceID: props.device,
      startDate: e.startDate,
      endDate: e.endDate,
    }

    await dispatch(fetchHistGeolocations(hprops))
  }

  const saveToJson = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(historygeolocations),
    )}`
    const link = document.createElement('a')
    link.href = jsonString
    link.download = 'data.json'

    link.click()
  }

  const removePolylines = () => {
    for (const k in historygeolocations) {
      if (historyPolylines[k]) historyPolylines[k]?.setMap(null)
      historyPolylines[k] = undefined
    }

    // setOpenHistoryDrawer(false)
  }

  let renderPolylines = () => {
    //iterating by device

    if (historygeolocationstatus === 'succeeded')
      for (const k in historygeolocations) {
        let path_coordinates: google.maps.LatLngLiteral[] = []
        const len = historygeolocations[k].length

        //TODO no need markers array improve this, get rid of markers and return marker
        const markers = historygeolocations[k].map(
          (element: any, i: number) => {
            const mpoint: google.maps.LatLngLiteral = {
              lat: Number(element.latitude),
              lng: Number(element.longitude),
            }
            path_coordinates.push(mpoint)
          },
        ) // map loop

        const lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        }

        // const usuario = drivers.find(
        //     (driver: Driver) => driver.id === e.target.value,
        //   )
        const polyline = new google.maps.Polyline({
          path: path_coordinates,
          geodesic: true,
          strokeColor: vehicles.find(
            (vehic: Vehicle) => vehic?.device?.id === k,
          )?.pathColor,
          strokeOpacity: 1,
          strokeWeight: 3,
          icons: [
            {
              icon: lineSymbol,
              offset: '100%',
              repeat: '100px',
            },
          ],
        })

        //TODO add listeners to polyline mouse over and onclick add marker to onClick and interpolate points for speed calculation, reverse geocoding and date!
        polyline.addListener('click', () => {})
        // if (location.pathname == '/GA') AnimateSymbol(polyline, 200, 20) // review for performance
        historyPolylines[k] = polyline

        if (props.map) historyPolylines[k]?.setMap(null)

        if (props.map) historyPolylines[k]?.setMap(props.map)
      }
  }

  const RemoveAndClear = () => {
    props.handleCloseButton()
    removePolylines()
  }
  return (
    <>
      <h3 style={{ marginLeft: 10 }}>
        {
          vehicles.find((item, inded) => item.device?.id === props.device)
            ?.name!
        }
      </h3>

      <Fragment>
        <Stack direction="row" spacing={2} alignItems={'center'}>
          <Typography style={{ marginLeft: 10 }}>Date Range</Typography>
          <select
            key={1}
            id="drivers"
            onChange={selectRange}
            style={{ marginLeft: 10, minHeight: 35, borderRadius: 5 }}
          >
            {ranges.map((range: string, index: number) => {
              return (
                <Fragment key={index}>
                  <option value={range}>{range}</option>
                </Fragment>
              )
            })}
          </select>
        </Stack>
        {historygeolocationstatus === 'succeeded' ? (
          selectCustom === 'Custom' && (
            <>
              {/* <Button onClick={saveToJson}>save</Button> */}
              <div>
                <TextField
                  value={getLocalDate(startDate)}
                  size="small"
                  id="StartDate"
                  type="datetime-local"
                  style={{
                    width: 215,
                    borderRadius: '0.25rem',
                    margin: 10,
                  }}
                  onChange={(e) => {
                    const startdate = new Date(e.target.value)
                    setStartDate(startdate)
                  }}
                />
              </div>
              <div>
                <TextField
                  value={getLocalDate(endDate)}
                  size="small"
                  id="EndDate"
                  type="datetime-local"
                  style={{ width: 215, margin: 10 }}
                  onChange={(e) => {
                    const enddate = new Date(e.target.value)
                    setEndDate(enddate)
                  }}
                />
              </div>

              <Button
                size="small"
                variant="contained"
                onClick={async () => {
                  const range = endDate.getDate() - startDate.getDate()
                  if (range <= 7) updateDates({ startDate, endDate })
                  else
                    alert('Please select a Date Window no bigger than a week')
                }}
                style={{ margin: 10 }}
              >
                Update Map
              </Button>
            </>
          )
        ) : (
          <div>
            <CircularProgress />
          </div>
        )}
        <Button onClick={removePolylines} style={{ marginTop: '10' }}>
          Clear Map
        </Button>

        {/* TODO */}
        <HistoryTimeline
          device={props.device}
          onMouseOver={(lat, lon) => {
            const mpoint: google.maps.LatLngLiteral = {
              lat: Number(lat),
              lng: Number(lon),
            }

            const row_marker = new google.maps.Marker({
              map: props.map,
              position: mpoint,
            })
            setmarker(row_marker)
          }}
          onMouseOut={(lat, lon) => {
            marker?.setMap(null)
          }}
        />
      </Fragment>
      {/* </Card> */}
    </>
  )
}

export default VehicleHistoryController
