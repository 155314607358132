import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { GeoLocation } from '../api/types'
import {
  Avatar,
  Container,
  FormControlLabel,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import useWindowSize from '../customHooks/useWindowSize'

type htlprops = {
  device: string | undefined
  onMouseOver(lat: number, lon: number): void
  onMouseOut(lat: number, lon: number): void
}
type htlrowprops = {
  item: GeoLocation
  onMouseOver(lat: number, lon: number): void
  onMouseOut(lat: number, lon: number): void
}

function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number {
  const earthRadiusMiles = 3958.8 // Radius of the Earth in miles

  const degToRad = (degrees: number) => degrees * (Math.PI / 180)

  const dLat = degToRad(lat2 - lat1)
  const dLon = degToRad(lon2 - lon1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(lat1)) *
      Math.cos(degToRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = earthRadiusMiles * c
  return distance
}

function estimateMilesTraveled(coordinates: GeoLocation[]): number {
  let totalDistance = 0
  if (coordinates)
    if (coordinates.length > 0)
      for (let i = 1; i < coordinates.length; i++) {
        const { latitude: lat1, longitude: lon1 } = coordinates[i - 1]
        const { latitude: lat2, longitude: lon2 } = coordinates[i]
        totalDistance += calculateDistance(lat1, lon1, lat2, lon2)
      }
  return totalDistance
}

const HistoryTimelineRow = (props: htlrowprops) => {
  const speedUnit = useSelector(
    (state: RootState) => state.usersettings?.usersettings?.speedUnit,
  )

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        onMouseEnter={() =>
          props.onMouseOver(props.item.latitude, props.item.longitude)
        }
        onMouseLeave={() =>
          props.onMouseOut(props.item.latitude, props.item.longitude)
        }
      >
        <TableCell>
          <Stack direction="row" alignItems="self-start" spacing={1}>
            {/* <Avatar alt={item.name} sx={{ backgroundColor: '#dfe2e7' }} /> */}
            <Typography noWrap style={{ fontSize: 10 }}>
              {new Date(props.item.timestamp).toLocaleTimeString('en-US', {
                // year: 'numeric',
                // month: 'long',
                // day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true,
              })}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* <Avatar alt={item.name} sx={{ backgroundColor: '#dfe2e7' }} /> */}

            {/* <Avatar alt={item.name} sx={{ backgroundColor: '#dfe2e7' }} /> */}
            <Typography noWrap>
              <label
                style={{
                  whiteSpace: 'pre-wrap',
                  maxWidth: '200px',
                  fontSize: 10,
                }}
              >
                {props.item.address}
              </label>
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* <Avatar alt={item.name} sx={{ backgroundColor: '#dfe2e7' }} /> */}
            <Typography>
              {props.item.speed &&
                (speedUnit
                  ? speedUnit === 'mile'
                    ? (props.item.speed / 1.6).toFixed(2) + ' mph'
                    : props.item.speed.toFixed(2) + ' kmh'
                  : props.item.speed)}
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  )
}

const HistoryTimeline = (props: htlprops) => {
  const windowSize = useWindowSize()
  const [Zero, setZero] = React.useState(true)
  const [mode, setMode] = React.useState('table')
  const historygeolocations = useSelector(
    (state: RootState) => state.histgeolocations.histgeolocations,
  )
  const historygeolocationsstatus = useSelector(
    (state: RootState) => state.histgeolocations.status,
  )
  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  return (
    <>
      <Container
        style={{
          marginTop: 4,
          padding: 0,
          width: '100%',
          height:
            windowSize.width > 800
              ? windowSize.height - 120
              : windowSize.height / 2.3,
          overflow: 'scroll',
        }}
      >
        <Typography sx={{ marginLeft: 1 }}>
          Miles:
          {estimateMilesTraveled(
            historygeolocations[props.device ? props.device : ''],
          ).toFixed(2)}
          .
        </Typography>
        <FormControlLabel
          control={
            <Switch
              onChange={() => {
                if (mode === '') setMode('table')
                else setMode('')
              }}
            />
          }
          label="list mode"
        />
        <FormControlLabel
          control={
            <Switch
              name="hide zero speeds"
              checked={Zero}
              onChange={() => {
                setZero(!Zero)
              }}
            />
          }
          label="hide zero speeds"
        />
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ verticalAlign: 'baseline' }}
          >
            <TableHead>
              <TableCell>Time</TableCell>
              <TableCell>Address </TableCell>
              <TableCell>Speed</TableCell>
            </TableHead>

            <TableBody></TableBody>
          </Table>
        </TableContainer>

        {props.device &&
        historygeolocationsstatus === 'succeeded' &&
        historygeolocations[props.device] ? (
          historygeolocations[props.device]

            .filter((item) => !Zero || (item.speed && item.speed > 0))
            .sort((a, b) => {
              const dateA = new Date(a.timestamp)
              const dateB = new Date(b.timestamp)
              return dateB.getTime() - dateA.getTime()
            })
            .map((item, index) => {
              return (
                <div key={index}>
                  {mode === 'table' ? (
                    <>
                      <HistoryTimelineRow
                        onMouseOver={props.onMouseOver}
                        onMouseOut={props.onMouseOut}
                        item={item}
                        key={index}
                      />
                    </>
                  ) : (
                    <Timeline
                      nonce={undefined}
                      onResize={undefined}
                      onResizeCapture={undefined}
                    >
                      <TimelineItem>
                        <TimelineOppositeContent color="textSecondary">
                          {item.address}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          {new Date(item.timestamp).toString()}
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  )}
                </div>
              )
            })
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}

export default HistoryTimeline
