import React, { useState, useEffect } from 'react'
import '../../styles/Login.css'
import CssBaseline from '@mui/material/CssBaseline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

type PasswordValidatorProps = {
  password: string
}

const PasswordValidatorComponent: React.FC<PasswordValidatorProps> = ({
  password,
}) => {
  const [conditionsArray, setCondsArr] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ])

  const checkLength = (str: string) => str.length >= 8
  const hasLowerCase = (str: string) => /[a-z]/.test(str)
  const hasUpperCase = (str: string) => /[A-Z]/.test(str)
  const hasNumber = (str: string) => /[0-9]/.test(str)
  const hasSpecialChar = (str: string) => /[-+_!@#$%^&*.,?]/.test(str)

  const checkAll = () => {
    const tempArr = [
      checkLength(password),
      hasLowerCase(password),
      hasUpperCase(password),
      hasNumber(password),
      hasSpecialChar(password),
    ]
    setCondsArr(tempArr)
  }

  useEffect(() => {
    checkAll()
  }, [password])

  const passwordRequirements: string[] = [
    '8-character minimum length',
    'Contains at least 1 lowercase letter',
    'Contains at least 1 uppercase letter',
    'Contains at least 1 number',
    'Contains at least 1 special character\n(-+_!@#$%^&*.,?)',
  ]

  return (
    <div>
      {passwordRequirements.map((req, index) => (
        <div key={index}>
          <label style={{ fontSize: 14 }}>{req}</label>
          {conditionsArray[index] ? (
            <CheckCircleIcon style={{ maxWidth: 18 }} />
          ) : null}
        </div>
      ))}
    </div>
  )
}

export default PasswordValidatorComponent
