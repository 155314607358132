import { useEffect, useRef, useState } from 'react'

import { MapProps } from '../api/types'

import '../styles/tracking-styles.css'
import { styles } from './mapstyles'

import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import MapController from './MapController'
import CustomSwipeableDrawer from "./CustomSwipeableDrawer/CustomSwipeableDrawer"

//do not delete

export const MainMap = (props: MapProps) => {
  const refmap = useRef<HTMLDivElement>(null)

  const [map, setMap] = useState<google.maps.Map>()

  //TODO check user styles? or user settings

  useEffect(() => {
    if (refmap.current && !map) {
      setMap(
        new google.maps.Map(refmap.current, {
          center: props.center,
          zoom: props.zoom,
          fullscreenControl: false,
          styles: styles[props.mapstyle],
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
        }),
      )
    }
  }, [refmap, map])
  useEffect(() => {
    if (map) map.setOptions({ styles: styles[props.mapstyle] })
  }, [props.mapstyle])

  return (
    <>
      <div
        className={`${props.enable_controller ? 'track-grid-container' : ''}`}
      >
        {/* review*/}
        <div id="list-wrap">
          {props.enable_controller && map && (
            <MapController {...props} map={map} />
            //<MapControllerMobile {...props} map={map}/>
          )}
        </div>
        <div
          id="map"
          style={{
            //   position: 'relative',
            height: props.height,
            //   width: '100%',
            //   overflow: 'hide',
          }}
          ref={refmap}
        >
          map
        </div>
      </div>
    </>
  )
}
