// Gauge.tsx

import './FuelGauge.css'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'

interface FuelGaugeProps {
  fuel: number
  maxValue: number
  gaugeSize: number
  scale: string
}

const FuelGauge = (props: FuelGaugeProps) => {
  const rotateValue = (props.fuel / props.maxValue) * 180 - 90 // Assumes maximum speed is 220, adjust as needed

  const renderTicks = () => {
    const tickAngles = [30, 60, 0, -30, -60]
    const tickValues = ['', 'E', '0.5 ', '', 'F']
    const adjustment = 0.8

    const ticks = []
    const numTicks = 5 // Change this value to adjust the number of ticks

    const labeladjustment = 0.6
    for (let i = 0; i < numTicks; i++) {
      const tickValue = tickValues[i]

      const tickRotation = tickAngles[i] + 90
      const labelRotation = tickAngles[i]
      const tickPositionX =
        props.gaugeSize / 2 + (props.gaugeSize / 2) * Math.cos(tickRotation * (Math.PI / 180)) * adjustment

      const tickPositionY =
        props.gaugeSize / 2 + (props.gaugeSize / 2) * Math.sin(tickRotation * (Math.PI / 180)) * adjustment

      const labelPositionX =
        props.gaugeSize / 2 + (props.gaugeSize / 2) * Math.cos(tickRotation * (Math.PI / 180)) * labeladjustment

      const labelPositionY =
        props.gaugeSize / 2 + (props.gaugeSize / 2) * Math.sin(tickRotation * (Math.PI / 180)) * labeladjustment

      ticks.push(
        <div key={i}>
          <b
            style={{
              fontSize: 10,
              position: 'absolute',
              left: labelPositionX,
              top: labelPositionY,
              transform: `rotate(${labelRotation}deg)`,
              color: 'white',
            }}
          >
            {tickValue}
          </b>
          <div
            key={i}
            className="gauge__tick"
            style={{
              left: tickPositionX,
              top: tickPositionY,
              transform: `rotate(${tickRotation - 90}deg)`,
            }}
          ></div>
        </div>,
      )
    }
    return ticks
  }
  const tickValue2 = 2
  return (
    <div className="fuel_gauge-container">
      <div className="fuel_gauge">
        {/* Render the gauge ticks */}
        {renderTicks()}
        ,
        <div className="fuel_gauge_needle" style={{ transform: `rotate(${rotateValue + 180}deg)` }} />
        <div className="fuel_gauge__speed" style={{ height: props.gaugeSize / 2 }}>
          {/* {props.fuel} {props.scale} */}
          <LocalGasStationIcon
            style={{
              position: 'absolute',
              top: -100,
              left: -20,
              width: 50,
              height: 50,
              color: 'white',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default FuelGauge
