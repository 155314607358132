import axios from 'axios'

import { URL } from './urls'
import { Entity } from './types'
const BASE_URL = URL.URl_ALL

//organizationId: string,
//sessionToken: string

export interface Response<T> {
  message?: string
  result: T[]
  query?: string[]
}

export const getAll = async <T>(
  url: string,
  organizationId: string,
  sessionToken: string,
): Promise<T[]> => {
  return new Promise((res) => {
    res(
      axios
        .get<Response<T>>(`${BASE_URL}/${url}`, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data.result
        })
        .catch((reason): T[] => {
          throw reason
        }),
    )
  })
}

export const addItem = async <T>(
  url: string,
  item: T,
  sessionToken: string,
  organizationId: string,
): Promise<any> => {
  return new Promise((res) => {
    res(
      axios
        .post<T>(`${BASE_URL}/${url}`, item, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          if (!navigator.onLine) {
            alert('Please check your internet connection')
          }
          throw reason
        }),
    )
  })
}

export const updateItem = async <T extends Entity>(
  url: string,
  item: T,
  sessionToken: string,
  organizationId: string,
): Promise<T> => {
  return new Promise((res) => {
    res(
      axios
        .patch<T>(`${BASE_URL}/${url}/${item.id}`, item, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          throw reason
        }),
    )
  })
}

export const updateDevice = async <T extends Entity>(
  url: string,
  item: T,
  sessionToken: string,
  organizationId: string,
): Promise<Entity> => {
  return new Promise((res) => {
    res(
      axios
        .patch<T>(
          `${BASE_URL}/${url}/${item.id}`,
          { name: item.name },
          {
            params: { organizationId },
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + sessionToken,
            },
          },
        )
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          throw reason
        }),
    )
  })
}
