import axios from 'axios'

import { URL } from './urls'
const BASE_URL = URL.URl_ALL

const f = (r: any): any => JSON.parse(r)

export const getDeviceData = async (
  deviceId: string,
  organizationId: string,
  sessionToken: string,
  startDate?: Date,
  endDate?: Date,
  retrieveDeviceData?: boolean,
  retrieveGeolocationData?: boolean | null,
  limit?: number,
  ascendingOrder?: boolean,
): Promise<any[]> => {
  const response = await axios.get<any>(`${BASE_URL}/deviceData/${deviceId}`, {
    params: {
      startDate,
      endDate,
      organizationId,
      retrieveDeviceData,
      retrieveGeolocationData,
      limit,
      ascendingOrder,
    },

    transformResponse: f,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  })

  //
  return response.data
}
