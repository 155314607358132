import React from 'react'
import Container from '@mui/material/Container'
import { useSelector } from 'react-redux'

export default function FooterSection() {
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  return (
    <Container sx={{marginTop:"20px",marginBottom:"100px"}}>
      <p {...(themeName==="light"?{style:{textAlign:"center",color:"#000000",fontFamily: 'Montserrat'}}:{style:{textAlign:"center",color:"#ffffff",fontFamily: 'Montserrat'}})}>Copyright vermmis.com 2023</p>
    </Container>
  )
}
