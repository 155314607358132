import * as React from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import Tooltip from '@mui/material/Tooltip'
import PersonAdd from '@mui/icons-material/PersonAdd'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { userLogout } from '../features/session/cogAccount'
import { deauthenticate } from '../features/session/sessionSlice'
import { useAppDispatch } from '../app/hooks'
import { RootState } from '../app/store'
import { useSelector } from 'react-redux'

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import GeoAccessList from './geofences/GeoAccessList'

import {
  Icon,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { getLocalDate } from './VehicleHistoryController'
import { Geofence, GeofenceAccess, Vehicle } from '../api/types'
import { selectAllVehicles } from '../features/vehicles/vehicleSlice'
import DateRangeSelector from './DateRangeSelector'
import { getGeoAccess } from '../api/geofences'
type prfmenprops = {
  status: boolean
}

export default function UserNotifications(props: prfmenprops) {
  const dispatch = useAppDispatch()
  let navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const [width, setWindowWidth] = useState(window.innerWidth)
  const [offset, setOffset] = useState(30)
  const session = useSelector((state: RootState) => state.session.userdata)

  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  const Geofences = useSelector((state: RootState) => state.geofences.geofences)

  const geofencesstatus = useSelector(
    (state: RootState) => state.geofences.status,
  )
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  let vehicles: Vehicle[] | undefined
  vehicles = useSelector(selectAllVehicles)
  const [events, setevents] = useState<GeofenceAccess[]>()

  let startDate: Date
  let endDate: Date

  startDate = new Date()
  endDate = new Date()

  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setSeconds(0)

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  useEffect(() => {
    if (props.status) setOffset(120)
  }, [props.status])

  useEffect(() => {
    getGeofencesData()
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const getGeofencesData = async () => {
    await getGeoAccess(organizationId, startDate, endDate, sessionToken)
      .then((data: GeofenceAccess[]) => {
        setevents(data)
      })
      .catch((err: Error) => {
        alert('Geofences acceses could not be fetched')
      })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const s1 = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  }

  const s2 = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  }
  // {`contenedor ${!inactive ? 'inactive' : ''}`}
  return (
    <div
      style={{
        zIndex: 1,
      }}
    >
      <Box sx={props.status ? s2 : s1}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <NotificationsNoneIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
      >
        {/* add Table\ header in geofence events */}
        <Table>
          {events?.map((acceso: GeofenceAccess, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell size="small">
                    {acceso.accessType === 'exit' && (
                      <Tooltip title={'Exit'}>
                        <Icon
                          color="primary"
                          sx={{ marginRight: 2, color: '#f50057' }}
                        >
                          {'logout'}
                        </Icon>
                      </Tooltip>
                    )}
                    {acceso.accessType === 'enter' && (
                      <Stack direction={'row'}>
                        <Tooltip title={'Enter'}>
                          <Icon sx={{ marginRight: 2, color: '#4caf50' }}>
                            {'login'}
                          </Icon>
                        </Tooltip>
                      </Stack>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {
                        vehicles?.find(
                          (vehic: Vehicle) => vehic.id === acceso.vehicleId,
                        )?.name
                      }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <label color="black">
                      {
                        Geofences?.find(
                          (value: Geofence) => value.id === acceso.geofenceId,
                        )?.name
                      }
                    </label>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontSize: 12 }}>
                      {getLocalDate(acceso.timestamp)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          })}
        </Table>
      </Menu>
    </div>
  )
}
