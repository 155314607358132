import { Container, Stack } from '@mui/material/'
import FeatureSection from '../../components/FeatureSection'
import { grey } from '@mui/material/colors'
import PricingSection from '../../components/PricingSection'
import CTASection from '../../components/CTASection'
import FooterSection from '../../components/FooterSection'
import HeaderSection from '../../components/HeaderSection'
import './styles.css'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { useNavigate } from 'react-router-dom'

export default function LandingPage() {
  const session = useSelector((state: RootState) => state.session)
  const navigate = useNavigate()
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  useEffect(() => {
    if (session.status === 'succeeded') {
      navigate('/tracking') //#TODO chane to url origin
    }
  }, [session.status])
  return (
    <div
      {...(themeName === 'light'
        ? { className: 'landing_page--main_container' }
        : { className: 'landing_page--main_container_dark' })}
    >
      <Stack
        direction="column"
        // {...(themeName === 'light'
        //   ? { style: { backgroundColor: 'white', width: '100vw' } }
        //   : { style: { backgroundColor: grey[900], width: '100vw' } })}
      >
        <div
        // {...(themeName === 'light'
        //   ? { className: 'landing_page--no_gradient landing_page--hecader_cta_container' }
        //   : { className: 'landing_page--gradient landing_page--hecader_cta_container' })}
        >
          <HeaderSection />
          <CTASection />
        </div>

        <div style={{ marginTop: '20px' }}>
          <FeatureSection />
        </div>
        <div className="landing_page_app_store_badges_container">
          <img
            src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
            height={'45px'}
            alt=""
          />
          <img src="google-play-badge.png" height={'60px'} alt="" />
        </div>
        <Container>
          {/* <PricingSection /> */}
          <FooterSection />
        </Container>
      </Stack>
    </div>
  )
}
