import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PersonIcon from '@mui/icons-material/Person'
import LanguageIcon from '@mui/icons-material/Language'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import EmailIcon from '@mui/icons-material/Email'
import './styles.css'
import { Box, Fade, Modal } from '@mui/material'
import SessionLogin from '../../../features/session/SessionLogin'
import ProductsMenu from '../../components/ProductsMenu'
import { useSelector } from 'react-redux'
import DarkLightModeSelector from '../DarkLightModeSelector/DarkLightModeSelector'
import { dark, light } from '@mui/material/styles/createPalette'
import CustomUserMenu from '../../../components/CustomUserMenu'
import LandingPageLogin from '../LandingPageLogin/LandingPageLogin'
import LandingPageMobileMenu from '../LandingPageMobileMenu/LandingPageMobileMenu'
const style = {
  position: 'absolute' as 'absolute',
  width: '50%',
  height: '50%',
  transform: 'translate(-0%, -0%)',
  backgroundColor: 'white',
  justifyItems: 'center',
  zIndex: 2,
}
export default function HeaderSection() {
  const [showLoginDialog, setShowLoginDialog] = useState(false)
  const handleOpen = () => setShowLoginDialog(true)
  const handleClose = () => setShowLoginDialog(false)
  /**-------------------------------------- */
  const [showSupportInfo, setShowSupportInfo] = useState(false)
  const handleSupportInfoOpen = () => setShowSupportInfo(true)
  const handleSupportInfoClose = () => setShowSupportInfo(false)
  /**-------------------------------------- */
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  return (
    <div
      {...(themeName === 'light'
        ? { className: 'header_section--main_container' }
        : { className: 'header_section--main_container_dark' })}
    >
      <div
        {...(themeName === 'light'
          ? { className: 'header_section' }
          : { className: 'header_section_dark' })}
      >
        <div className="header_section--title--container">
          <img
            src="Company_Logo.png"
            alt="Vermmis Logo"
            width={'50px'}
            style={{ marginLeft: '15px' }}
          />
          <text
            {...(themeName === 'light'
              ? { className: 'header_section--title--text' }
              : { className: 'header_section--title--text_dark' })}
          >
            Fleet Manager
          </text>
        </div>

        <div className="header_section--menu_options_container">
          <ProductsMenu autoOpen={true} />
          <Button
            variant="text"
            className="header_section--menu_bar--button"
            {...(themeName === 'light'
              ? { style: { color: 'black', fontFamily: 'Montserrat' } }
              : { style: { color: 'white', fontFamily: 'Montserrat' } })}
            onClick={handleSupportInfoOpen}
          >
            Contact Us
          </Button>

          {/* <Button
            variant="text"
            className="header_section--menu_bar--button"
            style={{ color: '#ffffff' }}
            startIcon={<LanguageIcon />}
          >
            Language
          </Button> */}
          {/* <Button
            variant="text"
            startIcon={<NotificationsIcon />}
            {...(themeName==="light"?{style:{color:'black'}}:{style:{color:'white'}})}
            size="large"
            className="header_section--menu_bar--button"
          /> */}
          <LandingPageLogin status={true} />
          <DarkLightModeSelector />
        </div>
        <LandingPageMobileMenu
          contactUsModalShowHandler={handleSupportInfoOpen}
        />
      </div>
      <Modal open={showLoginDialog} onClose={handleClose}>
        <SessionLogin />
      </Modal>
      <Modal open={showSupportInfo} onClose={handleSupportInfoClose}>
        <Fade in={showSupportInfo}>
          <Stack
            style={{ borderRadius: '10px' }}
            direction={'column'}
            sx={{
              alignItems: 'center',
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
            }}
          >
            <Box
              style={{ borderRadius: '10px 10px 0 0' }}
              sx={{
                backgroundColor: '#01314D',
                width: '350px',
                color: 'white',
                textAlign: 'center',
              }}
            >
              Contact VERMMIS support team
            </Box>
            <p style={{ width: '350px', padding: '10px' }}>
              Please don't hesitate to contact us at any time. We are happy to
              help you with any inquiries you may have, and our support team
              will be pleased to assist you.
            </p>
            <Stack>
              <Button variant="text" startIcon={<PhoneInTalkIcon />}>
                <a href="tel:+1-305-803-2630" style={{ margin: 0 }}>
                +1-305-803-2630
                </a>
              </Button>
              <Button variant="text" startIcon={<EmailIcon />}>
                <a
                  href="mailto:support@vermmis.com"
                  style={{ textTransform: 'lowercase', margin: 0 }}
                >
                  support@vermis.com
                </a>
              </Button>
            </Stack>
          </Stack>
        </Fade>
      </Modal>
    </div>
  )
}
