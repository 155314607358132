import { Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import '../styles/VehicleListItem.css'

import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

import { Driver, Vehicle } from '../api/types'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import PinDropIcon from '@mui/icons-material/PinDrop'

import { ShareLocationSettings } from './ShareLocationSettings'

import UpdateIcon from '@mui/icons-material/Update'
import NearMeIcon from '@mui/icons-material/NearMe'
import {
  Avatar,
  Button,
  Icon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
} from '@mui/material'
import { Stack } from '@mui/system'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { time } from 'console'

const iconURL =
  'https://images-ea57cbc6-0253-11ed-b939-0242ac120002.s3.amazonaws.com/'

type VehicleItemProps = {
  //TODO get rid of redundant data and pass the major info

  vehicle: Vehicle | undefined
  driver: Driver | null | undefined
  zoomin: (dev: string) => void
  clickHistory: (device: string) => void
  clickInfo: (device: string) => void

  device: string
  selected: string
  // history: (dev: string) => void
}

type rdprops = {
  children: ReactNode
  topLabel: string
}

type GMMItem = {
  icon: string
  label: string
  callback: () => void
}

type GMMArray = {
  menurArr: GMMItem[]
}

const GenericMoreMenu = (props: GMMArray) => {
  const [open, setOpen] = useState(null)
  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  return (
    <>
      <Tooltip title="Options">
        <Button sx={{ marginTop: '-5px' }} onClick={handleOpenMenu}>
          <MoreVertOutlinedIcon />
        </Button>
      </Tooltip>
      {open && (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 200,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuList>
            {props.menurArr.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={item.callback}
                  value={item.label}
                >
                  <ListItemText>{item.label}</ListItemText>
                  <Icon>{item.icon}</Icon>
                </MenuItem>
              )
            })}
          </MenuList>

          {/* <MenuItem sx={{ color: 'error.main' }}>View History</MenuItem> */}
        </Popover>
      )}
    </>
  )
}

const VehicleListItem = (props: VehicleItemProps) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [openShareWindow, setOpenShareWindow] = useState(false)
  const [openInfoWindow, setOpenInfoWindow] = useState(false)
  let navigate = useNavigate()

  const livegeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )

  const VehicleMenu: GMMItem[] = [
    {
      icon: 'info',
      label: 'vehicle info',
      callback: () => {
        props.clickInfo(props.device)

        // navigate('/tracking', { replace: true })
      },
    },

    {
      icon: 'history',
      label: 'tracking history',
      callback: () => {
        props.clickHistory(props.device)

        // navigate('/tracking', { replace: true })
      },
    },
    {
      icon: 'share',
      label: 'share',
      callback: () => {
        setOpenShareWindow(true)
      },
    },
  ]

  // TODO review this DO NOT ERASE

  const vehicleId = searchParams.get('vehicleId')
  const geofenceId = searchParams.get('geofenceId')

  const timestampUnix = livegeolocations.find((item, index) => {
    return item.deviceUniqueId === props.vehicle?.device?.id
  })?.timestamp
  const LocalDate = new Date(Number(timestampUnix)! * 1000)
  return (
    <div
      className={`vehicleItem ${
        props.selected === props.device ? 'active' : ''
      }`}
      onClick={() => {
        props.zoomin(props.device)
      }}
    >
      <div>
        <Stack direction="row">
          {/* <Avatar
            src={iconURL + props.vehicle?.icon}
            sx={{ backgroundColor: '#dfe2e7', width: 20, height: 20 }}
          /> */}
          <NearMeIcon style={{ color: props.vehicle?.pathColor }} />
          <Tooltip title={props.vehicle?.name}>
            <label
              style={{
                marginLeft: 2,
                fontWeight: 'bold',
                overflow: 'clip',
                width: '70%',
              }}
            >
              {props.vehicle?.name}
            </label>
          </Tooltip>
          <span style={{ position: 'absolute', right: 0 }}>
            <GenericMoreMenu menurArr={VehicleMenu} />
          </span>
        </Stack>
      </div>
      <div style={{ marginTop: 3 }}>
        <Stack direction="row">
          <Avatar
            // src={iconURL + props.vehicle?.driver}
            sx={{ backgroundColor: '#dfe2e7', width: 20, height: 20 }}
          />
          <label style={{ marginLeft: 2 }}>{props.driver?.name}</label>
          {/* <GenericMoreMenu menurArr={DriverMoreMenu} /> */}
        </Stack>
      </div>

      <div style={{ marginTop: 3 }}>
        {/* <Stack direction="row"> */}
        <PinDropIcon sx={{ color: '#2196f3', height: 20, width: 20 }} />
        <label style={{ fontSize: 12 }}>
          {
            livegeolocations.find((item, index) => {
              return item.deviceUniqueId === props.vehicle?.device?.id
            })?.address
          }
        </label>
        <div>
          <UpdateIcon sx={{ color: '#2196f3', height: 20, width: 20 }} />
          <label
            style={{
              fontSize: 10,
              fontWeight: 'medium',
              letterSpacing: 0,
              paddingBottom: 0,
              // position: 'absolute',
              right: 10,
            }}
          >
            {LocalDate.toLocaleDateString() +
              ' ' +
              LocalDate.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
          </label>
        </div>

        {/* </Stack> */}
      </div>
      <ShareLocationSettings
        open={openShareWindow}
        handleClose={() => setOpenShareWindow(false)}
        vehicleId={props.vehicle?.id}
      />
    </div>
  )
}
export default VehicleListItem
