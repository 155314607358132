import axios from 'axios'

import { User, UserSettings } from './types'
import { URL } from './urls'

const BASE_URL = URL.URl_ALL

export const getUserSettings = async (
  // this brings the user and his her settings..
  organizationId: string,
  userId: string,
  sessionToken: string,
): Promise<UserSettings> => {
  return new Promise((res) => {
    res(
      axios
        .get<any>(`${BASE_URL}/userSettings`, {
          params: { organizationId, userId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          console.log(response.data['result'][0])
          return response.data['result'][0]
        }),
    )
  })
}

export const updateUserSettings = async (
  // this brings the user and his her settings..
  organizationId: string,
  userSettings: UserSettings,
  sessionToken: string,
): Promise<UserSettings> => {
  return new Promise((res) => {
    res(
      axios
        .patch<any>(
          `${BASE_URL}/userSettings/${userSettings.user?.id}`,
          userSettings,
          {
            params: { organizationId },
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + sessionToken,
            },
          },
        )
        .then((response) => {
          return response.data
        }),
    )
  })
}
