import React, { useState } from 'react'

import { Button, MenuItem, Popover, Tooltip } from '@mui/material'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ConfirmationWindow from './ConfirmationWindow'
import { EditDelProps } from '../api/types'

function EditDeletePopMenu(props: EditDelProps) {
  const [open, setOpen] = useState(null)

  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  return (
    <>
      <Tooltip title="Edit Driver">
        <Button sx={{ marginTop: '-5px' }} onClick={handleOpenMenu}>
          <MoreVertOutlinedIcon />
        </Button>
      </Tooltip>
      {open && (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem value={props.id} onClick={() => props.handleEdit(props.id)}>
            <EditOutlinedIcon sx={{ marginRight: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}>
            <ConfirmationWindow
              message={props.confirmationMessage}
              callbackFunction={() => {
                props.handleDelete(props.id)
                handleCloseMenu()
              }}
            />
          </MenuItem>
        </Popover>
      )}
    </>
  )
}

export default EditDeletePopMenu
