import { Button, Stack } from "@mui/material";
import React from "react";
import FeatureChip from "../FeatureChip";
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { Container } from "react-bootstrap";
import "./index.css"
import { useSelector } from "react-redux";
import SendIcon from '@mui/icons-material/Send'
import FeaturesCarousel from "../FeaturesCarousel/FeaturesCarousel"
import HistoryTrackingIcon from "../../../../public/History Tracking ICON.svg"

export default function FeatureSection() {
    const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
      /**-------------------------------------- */
  /**STYLE MEDIA QUERIES HERE */
  /**-------------------------------------- */
  const getStartedButtonStyle = ()=>{
    const xSmall = window.matchMedia("(max-width: 575px) and (orientation: portrait)")
    if (xSmall.matches) {
      return { height: 'fit-content',width:"fit-content", fontFamily: 'Montserrat',fontSize:"6pt",marginTop:"20px" };
    }

    //Default in case no one is met
    return { height: 'fit-content',width:"fit-content", fontFamily: 'Montserrat',fontSize:"12pt",marginTop:"20px" };
  }
    return(
        <div className="feature_section--main_container">
            <div {...(themeName === 'light'
          ? { className: 'feature_section--title' }
          : { className: 'feature_section--title_dark' })}>
                What we offer?
            </div>
            <div className="feature_section--feature_chips_container">
            <FeatureChip bannerImage="featureRealTimeTracking.jpg" title='Real-Time Tracking' features={["Keep tabs on your entire fleet's whereabouts, 24/7, from any device."]} icon={<PinDropIcon sx={{fontSize:"50px",color:"#FF9900"}}/>}/>
            <FeatureChip bannerImage="featureHistoryTracking.jpg" title='History Tracking' features={["Go back in time and review all the tracks."]} icon={<img src="History Tracking ICON.png" width={"60px"} color="red"/>}/>
            <FeatureChip bannerImage="featureInstantNotifications.jpg" title='Instant Notifications' features={["Stay in the know with automatic alerts for events that matter most to you."]} icon={<PhoneAndroidIcon sx={{fontSize:"50px",color:"#D11ED4"}}/>}/>
            <FeatureChip bannerImage="featureDataDrivenReports.jpg" title='Data-Driven Reports' features={["Gain valuable insights to optimize routes, enhance efficiency, and reduce costs."]} icon={<LeaderboardIcon sx={{fontSize:"50px",color:"#10BAF0"}}/>}/>
            </div>
            <FeaturesCarousel autoPlay={true} interval={3000}/>
            <div {...(themeName === 'light'
          ? { className: 'feature_section--description' }
          : { className: 'feature_section--description_dark' })}>
                Join the future of fleet management today with our intuitive and robust tracking system. See how easily you can take control of your miles and streamline your operations.
            </div>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              style={getStartedButtonStyle()}
            >
              Get Started
            </Button>
        </div>
    );
}