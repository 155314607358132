import React from 'react'

//material

import List from '@mui/material/List'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'

import ListItemText from '@mui/material/ListItemText'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  Avatar,
  Checkbox,
  ListItemAvatar,
  SelectChangeEvent,
  Switch,
} from '@mui/material'
//material

import useWindowSize from '../../customHooks/useWindowSize'
import { selectAllVehicles } from '../../features/vehicles/vehicleSlice'
import { useSelector } from 'react-redux'

export type GeofenceNewprops = {
  NewGeoNameChanged: (e: any) => void
  selectGeometry: (event: React.ChangeEvent<HTMLInputElement>) => void
  SaveNewGeofence: () => void
  CancelAll: () => void
  handleToggleNew: (a: string) => any
  GeofenceLinkedVehicles: any
}

function GeofenceNew(props: GeofenceNewprops) {
  const [allSelected, setAllselected] = React.useState(true)

  const vehicles = useSelector(selectAllVehicles)
  const windowSize = useWindowSize()

  const handleSelect = (event: SelectChangeEvent) => {
    setAllselected(!allSelected)
  }

  return (
    <div style={{ marginLeft: 10 }}>
      <Typography>Geofence Name </Typography>
      <div>
        <input type="text" onChange={props.NewGeoNameChanged} />
      </div>
      <Typography>Geofence type</Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="Rectangle"
        name="radio-buttons-group"
        onChange={props.selectGeometry}
      >
        <FormControlLabel
          value="Rectangle"
          control={<Radio />}
          label="Rectangle"
        />
      </RadioGroup>
      <Typography style={{ marginTop: 20 }}>Alert Type</Typography>
      <RadioGroup
        aria-labelledby="onentrance"
        defaultValue="onentrance"
        name="radio-buttons-group"
      >
        <FormControlLabel
          value="onentrance"
          control={<Radio />}
          label="On Entrance"
        />
        <FormControlLabel value="onexit" control={<Radio />} label="On Exit" />
        <FormControlLabel value="onboth" control={<Radio />} label="On Both" />
      </RadioGroup>
      <Typography> Associated Vehicles</Typography>
      <FormControlLabel
        control={<Switch defaultChecked onChange={handleSelect} />}
        label="All"
      />

      {!allSelected && (
        <List
          dense
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            overflow: 'scroll',
            height: windowSize.height / 2.5,
          }}
        >
          {vehicles.map((item, index) => {
            return (
              <ListItem
                key={index}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    inputProps={{ 'aria-labelledby': item.name }}
                    onChange={props.handleToggleNew(item.id!)}
                    checked={props.GeofenceLinkedVehicles?.includes(item.id)}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar n°${index + 1}`}
                      // src={iconbaseurl + item.icon}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={item.name}
                    primary={`${item.name} ${index + 1}`}
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      )}
      <div>
        <Button
          onClick={() => {
            props.SaveNewGeofence()
          }}
        >
          Save
        </Button>
        <Button onClick={props.CancelAll}>Cancel</Button>
      </div>
    </div>
  )
}

export default GeofenceNew
