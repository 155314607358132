import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Container,
  Divider,
  Icon,
  List,
  Stack,
  Typography,
} from '@mui/material'

import ShareLocationIcon from '@mui/icons-material/ShareLocation'
import { CustomGeofence } from '../../api/types'
import useWindowSize from '../../customHooks/useWindowSize'

import EditDeletePopMenu from '../EditDeletePopMenu'
import './geostyles.css'
import useClickAway from '../../customHooks/useClickAway'

export type GeofenceListProps = {
  map: google.maps.Map | undefined
  GeoFenceArray: CustomGeofence[]
  DrawDragGeoRectangle: (map: google.maps.Map) => void
  handleEdit: (id: string) => void
  deleteGeofence: (id: string) => void
  hideButton: boolean
}

function GeofencesList(props: GeofenceListProps) {
  const windowSize = useWindowSize()
  const [GeoFenceArray, SetGeoFenceArray] = useState<CustomGeofence[]>([])
  const [selected, setSelected] = useState('')
  const listContainerRef = useRef(null)
  useEffect(() => {}, [props.GeoFenceArray.length])

  const handleDeselect = () => {
    setSelected('')
  }

  useClickAway(listContainerRef, handleDeselect)
  return (
    <div ref={listContainerRef}>
      <Stack direction={'row'}>
        <Button
          disabled={props.hideButton}
          onClick={() => {
            if (props.map) props.DrawDragGeoRectangle(props.map)
          }}
          style={{ marginBottom: '5px' }}
        >
          + Add Geofence
        </Button>
      </Stack>
      <Container
        style={{
          marginTop: 4,
          width: '100%',
          height:
            windowSize.width > 800
              ? windowSize.height - 180
              : windowSize.height / 2.3,
          overflow: 'scroll',
          padding: 0,
        }}
      >
        <List sx={{ padding: 0, margin: 0 }}>
          {props.GeoFenceArray.filter((value) => value.Geofence.status === 'A')
            // TODO toggle sort order!!
            .sort((a: CustomGeofence, b: CustomGeofence) =>
              a.Geofence.name.localeCompare(b.Geofence.name),
            )
            .map((value, index) => {
              const labelId = `checkbox-list-label-${value}`
              return (
                <div
                  className={`geofenceItem ${
                    selected === value.Geofence.name ? 'active' : ''
                  }`}
                  style={{}}
                  onClick={() => {
                    if (props.map) {
                      const bounds = props.GeoFenceArray.find(
                        (item) => item.Geofence.id === value.Geofence.id,
                      )?.figure.getBounds()
                      if (bounds) props.map?.fitBounds(bounds)
                      setSelected(value.Geofence.name)
                    }
                  }}
                  key={value.Geofence.id}
                >
                  <Stack direction="row">
                    <Button
                      style={{ padding: 0, margin: 0 }}
                      role={undefined}
                      // onClick={() => {
                      //   if (props.map) {
                      //     const bounds = props.GeoFenceArray.find(
                      //       (item) => item.Geofence.id === value.Geofence.id,
                      //     )?.figure.getBounds()
                      //     if (bounds) props.map?.fitBounds(bounds)
                      //   }
                      // }}
                    >
                      <ShareLocationIcon color="primary" />
                    </Button>
                    <label
                      style={{
                        marginLeft: -4,
                        fontWeight: 'bold',
                        overflow: 'clip',
                        width: '70%',
                      }}
                    >
                      {value.Geofence.name}
                    </label>
                    <span style={{ position: 'absolute', right: 0 }}>
                      <EditDeletePopMenu
                        confirmationMessage="Are you sure you want to delete this Geofence"
                        handleEdit={props.handleEdit}
                        handleDelete={props.deleteGeofence}
                        id={value.Geofence.id!}
                      />
                    </span>
                  </Stack>
                  <Stack
                    direction="row"
                    style={{ marginLeft: 22, marginTop: 10, marginBottom: 10 }}
                  >
                    <Icon color="primary">polyline</Icon>

                    <Typography style={{ marginLeft: 14 }}>
                      {value.Geofence.type}{' '}
                    </Typography>
                  </Stack>
                  <Divider style={{ height: 1, backgroundColor: '#000' }} />
                </div>
              )
            })}
        </List>
      </Container>
    </div>
  )
}

export default GeofencesList
