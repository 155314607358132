import {
    Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Modal,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import {Container} from '@mui/material'

type VideoDisplayProps = {
  onClose:any;
}
export default function VideoDisplay({onClose}:VideoDisplayProps) {
  return (
      <Card
      >

        <CardActionArea>
        <span onClick={onClose} className="material-icons" style={{position:"absolute",color:"white",right:"0px",zIndex:"200"}}>
close
</span>
          <CardMedia
            component="video"
            image={'https://localizem.com/video/VERMMIS_FEATURE_VIDEO.mp4'}
            autoPlay
            controls
            onEnded={onClose}
          />
        </CardActionArea>
      </Card>
  )
}
