import React from 'react'
import './BottomTabs.css'
import { useNavigate } from 'react-router-dom'

export default function BottomTabs() {
  /**----------------------------------------------------------*/
  /** CONSTANTS HERE                                           */
  /**----------------------------------------------------------*/
  const navigate = useNavigate()

  /*-------------------------------------------------------------------------- */
  /** STATE VARIABLES HERE                                                     */
  /*-------------------------------------------------------------------------- */

  /**----------------------------------------------------------*/
  /** HANDLERS HERE                                            */
  /**----------------------------------------------------------*/
  const handleTrackingButtonClick = () => {
    navigate('/tracking')
  }
  const handleVehiclesButtonClick = () => {
    navigate('/settings/vehicles')
  }
  const handleDriversButtonClick = () => {
    navigate('/settings/drivers')
  }
  const handleDevicesButtonClick = () => {
    navigate('/settings/devices')
  }
  const handleSettingsButtonClick = () => {
    navigate('/settings')
  }
  
  return (
    <div className="BottomTabs--MainContainer">
      <div className="BottomTab--Tab">
        <span
          onClick={handleTrackingButtonClick}
          className="material-symbols-outlined BottomTab--ButtonIcon"
        >
          mode_of_travel
        </span>
      </div>
      <div className="BottomTab--Tab">
        <span
          onClick={handleVehiclesButtonClick}
          className="material-symbols-outlined BottomTab--ButtonIcon"
        >
          local_shipping
        </span>
      </div>
      <div className="BottomTab--Tab">
        <span
          onClick={handleDriversButtonClick}
          className="material-symbols-outlined BottomTab--ButtonIcon"
        >
          person
        </span>
      </div>
      <div className="BottomTab--Tab">
        <span
          onClick={handleDevicesButtonClick}
          className="material-symbols-outlined BottomTab--ButtonIcon"
        >
          sensors
        </span>
      </div>
      <div className="BottomTab--Tab">
        <span
          onClick={handleSettingsButtonClick}
          className="material-symbols-outlined BottomTab--ButtonIcon"
        >
          settings
        </span>
      </div>
    </div>
  )
}
