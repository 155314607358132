export const timezones = {
  //Change this formatt
  '(GMT-10:00) Hawaii Time(HT)': 'Pacific/Honolulu',
  'Alaska	UTC−09:00	UTC−08:00': 'America: Alaska',
  '(GMT-08:00) Pacific Time(PT)': 'America/Los_Angeles',
  '(GMT-07:00) Mountain Time(MT)': 'America/Denver',
  '(GMT-06:00) Central Time (CT)': 'America/Chicago',
  '(GMT-05:00) Eastern Time (ET)': 'America/New_York',
}

// Atlantic	UTC−04:00	(not observed)	Puerto Rico, U.S. Virgin Islands
// Eastern	UTC−05:00	UTC−04:00	Connecticut, Delaware, District of Columbia, Georgia, Maine, Maryland, Massachusetts, New Hampshire, New Jersey, New York, North Carolina, Ohio, Pennsylvania, Rhode Island, South Carolina, Vermont, Virginia, West Virginia; Partially: Florida, Indiana, Kentucky, Michigan, Tennessee; No DST observed, not defined by 15 U.S.C. §260: Navassa Island, Bajo Nuevo Bank, Serranilla Bank
// Central	UTC−06:00	UTC−05:00	Alabama, Arkansas, Illinois, Iowa, Louisiana, Minnesota, Mississippi, Missouri, Oklahoma, Wisconsin; Partially: Florida, Indiana, Kansas, Kentucky, Michigan, Nebraska, North Dakota, South Dakota, Tennessee, Texas
// Mountain	UTC−07:00	UTC−06:00	Arizona (no DST outside of Navajo Nation), Colorado, Montana, New Mexico, Utah, Wyoming; Partially: Idaho, Kansas, Nebraska, Nevada, North Dakota, Oregon, South Dakota, Texas
// Pacific	UTC−08:00	UTC−07:00	California, Washington (state); Partially: Idaho, Nevada, Oregon
// Alaska	UTC−09:00	UTC−08:00	Partially: Alaska
// Hawaii–Aleutian	UTC−10:00	UTC−09:00	Hawaii (no DST observed in Hawaii); Partially: Alaska; No DST observed, not defined by 15 U.S.C. §260: Johnston Atoll
// American Samoa	UTC−11:00	(not observed)	American Samoa; Not defined by 15 U.S.C. §260: Jarvis Island, Midway Atoll, Palmyra Atoll, Kingman Reef
// —	UTC−12:00	(not observed)	Not defined by 15 U.S.C. §260: Baker Island, Howland Island
// —	UTC+12:00	(not observed)	Not defined by 15 U.S.C. §260: Wake Island
// Chamorro	UTC+10:00	(not observed)	Guam, Northern Mariana Islands
