import { useState, useEffect, useRef } from 'react'

import * as React from 'react'
import GeofenceNew from './GeofenceNew'

import { Alert, Divider } from '@mui/material'

import { Geofence, MapProps, CustomGeofence, secureCompany, Geometry } from '../../api/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { addGeofence, getGeofences, updateGeofence, updateGeofenceVehicles } from '../../api/geofences'
import GeofenceEdit from './GeofenceEdit'
import RightDrawer from '../RightDrawer'

import { styles } from '../mapstyles'

import GeofencesList from './GeofencesList'

//do not delete

import { useAppDispatch } from '../../app/hooks'
import { fetchGeofences, selectAllGeofences } from '../../features/geofences/geofenceSlice'
export interface Theme {}
export interface MapControllerprops extends MapProps {
  map: google.maps.Map | undefined
  selectedTab: string
}

function GeofencesController(props: MapControllerprops) {
  const dispatch = useAppDispatch()
  const [editIndex, seteditIndex] = useState<number | null>(null)
  const [onEditGeofence, setOnEdit] = useState<CustomGeofence | undefined>()

  const [newBounds, setNewBounds] = React.useState<any | google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral>()
  const [GeoFenceArray, SetGeoFenceArray] = useState<CustomGeofence[]>([])
  const refdraw = useRef<HTMLDivElement>(null)
  const [drawingManager, setdraman] = useState<google.maps.drawing.DrawingManager>()

  const [newGeoMode, setNewMode] = React.useState(false)
  const [editmode, setEditMode] = React.useState(false)
  const [newRectangle, setNewRectangle] = useState<google.maps.Rectangle>()
  const [newGeofenceName, setsetNewgeofenceName] = React.useState<string>('')
  const [newGeometry, setSelectedValue] = React.useState('')
  const [origBounds, setorigBounds] = React.useState<google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral | null>(
    null,
  )
  const [editedCoordinates, setEditedCoordinates] = useState<number[][]>()

  const sessionToken = useSelector((state: RootState) => state.session.userdata.accesstoken)
  const organizationId = useSelector((state: RootState) => state.session.userdata.organizationId)

  const [GeofenceLinkedVehicles, setLinkedVehicles] = React.useState<any>([])

  const defaultColor: google.maps.RectangleOptions = { fillColor: '#000000' }
  const editColor: google.maps.RectangleOptions = { fillColor: '#FF0000' }

  // geofences
  const [GeofencesArray, SetGeofencesArray] = useState<Geofence[]>()
  const geofencestatus = useSelector((state: RootState) => state.geofences.status)

  //Do not erase yet
  // const drawingManager = new google.maps.drawing.DrawingManager({
  //   drawingControl: true,
  //   drawingControlOptions: {
  //     position: google.maps.ControlPosition.TOP_CENTER,
  //     drawingModes: [
  //       // google.maps.drawing.OverlayType.MARKER,
  //       //  google.maps.drawing.OverlayType.CIRCLE,
  //       // google.maps.drawing.OverlayType.POLYGON,
  //       // google.maps.drawing.OverlayType.POLYLINE,
  //       google.maps.drawing.OverlayType.RECTANGLE,
  //     ],
  //   },

  //   circleOptions: {
  //     fillColor: '#ffff00',
  //     fillOpacity: 1,
  //     strokeWeight: 5,
  //     clickable: false,
  //     editable: true,
  //     zIndex: 1,
  //   },
  // })

  useEffect(() => {
    if (geofencestatus === 'idle') {
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId,
      }

      dispatch(fetchGeofences(secCompany))
    }

    //TODO this brings a lot of issues at the edited state, probably due to bad programming desitions from my side when did this initally :(
    // if (!GeofencesArray && geofencestatus === 'succeeded')
    //   SetGeofencesArray(allgeofences)
  }, [geofencestatus, dispatch])

  useEffect(() => {
    if (!drawingManager) {
      setdraman(
        new google.maps.drawing.DrawingManager({
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              // google.maps.drawing.OverlayType.MARKER,
              //  google.maps.drawing.OverlayType.CIRCLE,
              // google.maps.drawing.OverlayType.POLYGON,
              // google.maps.drawing.OverlayType.POLYLINE,
              google.maps.drawing.OverlayType.RECTANGLE,
            ],
          },

          circleOptions: {
            fillColor: '#ffff00',
            fillOpacity: 1,
            strokeWeight: 5,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }),
      )
    }
  }, [refdraw])

  useEffect(() => {
    if (props.map) changeMapStyle(props.map, props.mapstyle)
  }, [props.mapstyle])

  const changeMapStyle = (map: google.maps.Map, style: string) => {
    map?.setOptions({ styles: styles[style] })
  }

  //CENTERING MAP
  useEffect(() => {
    setMapZoom(props.center)

    if (props.map) {
      //map.panTo(props.center)
      const latlongi = new google.maps.LatLng(props.center.lat, props.center.lng)
      //  smoothlyAnimatePanTo(map, latlongi)
    }
  }, [props.center])

  const setMapZoom = (cpoint: google.maps.LatLngLiteral) => {
    props.map?.setZoom(18)
    props.map?.setCenter(cpoint)
  }

  //******set  geofences to state
  useEffect(() => {
    getGeofencesData()
  }, [])

  const getGeofencesData = async () => {
    await getGeofences(organizationId, sessionToken)
      .then((data: Geofence[]) => {
        data.map((item, index) => {
          const geome: Geometry = JSON.parse(item?.geometry?.toString())
          item.geometry = geome
        })
        SetGeofencesArray(data)
      })
      .catch((err: Error) => {
        if (!navigator.onLine) {
          alert('Please check your internet connection')
        } else {
          alert('Error' + err)
        }
      })
  }

  useEffect(() => {
    let tempGeoFenceArray: CustomGeofence[] = []

    if (GeofencesArray) {
      GeofencesArray.map((geof, index) => {
        if (geof.type?.toLowerCase() === 'rectangle') {
          const bounds = {
            north: geof.geometry?.coordinates[0][0][1], // north lat
            south: geof.geometry?.coordinates[0][2][1], // south lat
            east: geof.geometry?.coordinates[0][2][0], //east lat
            west: geof.geometry?.coordinates[0][0][0], // west lat
          }

          geof.geometry?.coordinates.map((coordinate: any[], index) => {
            coordinate.map((singlecoord: number[], index) => {})
          })
          const rectangle = new google.maps.Rectangle({
            bounds: bounds,
          })

          const tempCGeofence: CustomGeofence = {
            Geofence: geof,
            figure: rectangle,
          }
          tempGeoFenceArray.push(tempCGeofence)
        }
        //Polygon
        else {
        }
        SetGeoFenceArray(tempGeoFenceArray)
      })
    }

    return () => {}
  }, [GeofencesArray])

  //set all geofences in map
  useEffect(() => {
    if (props.map && props.selectedTab === 'Geofences') setonMapAll(GeoFenceArray, props.map)
    else setonMapAll(GeoFenceArray, null)
  }, [GeoFenceArray, props.selectedTab])

  const setonMapAll = (array: CustomGeofence[], map: google.maps.Map | null) => {
    array.forEach((element) => {
      let infoWindow = new google.maps.InfoWindow()

      element.figure.addListener('click', () => {
        //element.figure.setDraggable(true) // do we want to
        //TODO highlight the list

        const sw = element.figure.getBounds()!.getSouthWest()
        const ne = element.figure.getBounds()!.getNorthEast()

        //    infoWindow.setContent(showNewRect(element.figure))
        infoWindow.setContent(element.Geofence.name + ne)
        infoWindow.setPosition(ne)
        infoWindow.open(map)
      })
      element.figure.addListener('bounds_changed', () => {
        const ne = element.figure.getBounds()!.getNorthEast()
        const sw = element.figure.getBounds()!.getSouthWest()

        const updcoordinateArr = [
          [sw.lng(), sw.lat()],
          [ne.lng(), sw.lat()],
          [ne.lng(), ne.lat()],
          [sw.lng(), ne.lat()],
          [sw.lng(), sw.lat()],
        ]

        setEditedCoordinates(updcoordinateArr)
      })

      element.figure.setMap(map)
    })
  }

  // const handleEdition = (value: number) => {
  const handleEdition = (id: string) => {
    console.log(id)
    const tempGeo = GeoFenceArray.find((value: CustomGeofence, index) => value.Geofence.id === id)

    const value = GeoFenceArray.findIndex((value) => value.Geofence.id === id)
    console.log(tempGeo)
    if (!editmode && !newGeoMode && tempGeo) {
      setOnEdit({ ...GeoFenceArray[value] })
      setEditMode(true)

      seteditIndex(value)
      const sw = tempGeo.figure.getBounds()!.getSouthWest()
      const ne = tempGeo.figure.getBounds()!.getNorthEast()
      const beforeEditingBounds = GeoFenceArray[value].figure.getBounds()
      setorigBounds(beforeEditingBounds)
      //
      const originalCoordinates = [
        [sw.lng(), sw.lat()],
        [ne.lng(), sw.lat()],
        [ne.lng(), ne.lat()],
        [sw.lng(), ne.lat()],
        [sw.lng(), sw.lat()],
      ]

      setEditedCoordinates(originalCoordinates)

      const center = {
        lat: tempGeo.Geofence.geometry?.coordinates[0][0][1],
        lng: tempGeo.Geofence.geometry?.coordinates[0][0][0],
      }
      // map?.setCenter(center)
      // map?.setZoom(12)

      //reseting
      GeoFenceArray.forEach((element) => {
        element.figure.setOptions(defaultColor)
      })

      tempGeo.figure.setOptions(editColor)
      tempGeo.figure.setDraggable(true)
      tempGeo.figure.setEditable(true)

      //add this two on edit
      // GeoFenceArray[value].figure.addListener('bounds_changed', onEditDragStart)
      // GeoFenceArray[value].figure.addListener('rightclick', onRightClick)
    } else {
      alert('Please save or disregard your changes first.')
    }
  }

  const GeofencefromRectangle = (rectangle: google.maps.Rectangle) => {
    const ne = rectangle.getBounds()?.getNorthEast()
    const sw = rectangle.getBounds()?.getSouthWest()

    const coordinateArr = [
      [sw?.lng(), sw?.lat()], // repeated required by backend to close the figure**(do not erase)
      [ne?.lng(), sw?.lat()],
      [ne?.lng(), ne?.lat()],
      [sw?.lng(), ne?.lat()],
      [sw?.lng(), sw?.lat()], //repeated required by backend to close the figure**(do not erase)
    ]

    const tempgeof: Geofence = {
      name: newGeofenceName,
      type: newGeometry, //  TODO newGeometry hardcoded to Rectangle for now
      geometry: { type: 'Polygon', coordinates: [coordinateArr] },
    }

    return tempgeof
  }

  const saveGeofencetoApi = async (map: google.maps.Map, geofence: CustomGeofence) => {
    await addGeofence(geofence.Geofence, organizationId, sessionToken).then((data) => {
      if (data) {
        alert('Geofence saved correctly')

        setNewMode(false)
        const tempGeoFenceArray = GeoFenceArray
        // TODO add figure type when save it
        geofence.Geofence.id = data['result']['id']
        geofence.Geofence.status = 'A'
        tempGeoFenceArray.push(geofence)
        SetGeoFenceArray(tempGeoFenceArray)

        newRectangle?.setMap(null)
        if (map) GeoFenceArray[GeoFenceArray.length - 1].figure.setMap(map)

        // call update vehicles here
      }
    })
  }

  const updateGeofencetoApi = async (geofence: CustomGeofence) => {
    await updateGeofence(geofence.Geofence, organizationId, sessionToken)
      .then((data) => {
        if (data) {
          alert('Geofence updated correctly')
          setEditMode(false)
          setEditedCoordinates(undefined)
          if (editIndex != null) {
            GeoFenceArray[editIndex].figure.setOptions(defaultColor)
            GeoFenceArray[editIndex].figure.setDraggable(false)
            GeoFenceArray[editIndex].figure.setEditable(false)
          }
        }
      })
      .catch((err) => {
        alert('There was an error updating the geofence, please try again later.' + err)
      })
  }

  const deleteGeofencetoApi = async (geofence: CustomGeofence, index: number) => {
    await updateGeofence(geofence.Geofence, organizationId, sessionToken)
      .then((data) => {
        if (data) {
          alert('Geofence deleted correctly')
          setEditMode(false)
          if (index != null) {
            GeoFenceArray[index].figure.setMap(null)
            GeoFenceArray.splice(index, 1)
          }
        }
      })
      .catch((err) => {
        alert('There was an error updating the geofence, please try again later.')
      })
  }

  const deleteGeofenceCallback = (id: string) => {
    console.log(id)

    if (id) {
      const tempGeo = GeoFenceArray.find((value: CustomGeofence, index) => value.Geofence.id === id)
      if (tempGeo) {
        const index = GeoFenceArray.findIndex((value) => value.Geofence.id === id)

        tempGeo.Geofence.status = 'D'
        deleteGeofencetoApi(tempGeo, index)
        GeoFenceArray[index].figure.setMap(null)
      }
    }
  }
  const startUpdGeofence = (name: string) => {
    if (editIndex != null) {
      let tempGeofence = { ...GeoFenceArray[editIndex] }
      tempGeofence.Geofence.name = name
      if (tempGeofence.Geofence.geometry) tempGeofence.Geofence.geometry.coordinates = [editedCoordinates]

      updateGeofencetoApi(tempGeofence)
    }
  }
  //TODO modify this to getr center of the map and assign offset to the left and right to get a rectangle

  function convertToStringObjectArray(stringArray: string[]): { id: string }[] {
    return stringArray.map((element) => ({ id: element }))
  }

  //using drawing manager for now, do not delete yet

  const DrawDragGeoRectangle = (map: google.maps.Map) => {
    setNewMode(true)

    drawingManager?.setMap(map)

    google.maps.event.addListener(drawingManager!, 'circlecomplete', function (circle: any) {})

    google.maps.event.addListener(
      drawingManager!,
      'rectanglecomplete',
      function (temprectangle: google.maps.Rectangle) {
        setNewBounds(temprectangle.getBounds())
        drawingManager?.setMap(null)

        temprectangle?.addListener('bounds_changed', () => {
          setNewBounds(temprectangle.getBounds())
        })
        temprectangle.setOptions(editColor)
        temprectangle.setEditable(true)

        temprectangle?.addListener('rightclick', () => {})
        setNewRectangle(temprectangle)

        setNewMode(true)
      },
    )
  }
  const CancelAll = () => {
    if (newRectangle) {
      newRectangle.setMap(null)
    }

    if (editIndex != null) {
      GeoFenceArray[editIndex].figure.setOptions(defaultColor)
      GeoFenceArray[editIndex].figure.setDraggable(false)
      GeoFenceArray[editIndex].figure.setEditable(false)
      GeoFenceArray[editIndex].figure.setMap(null)
      if (props.map) {
        GeoFenceArray[editIndex].figure.setBounds(origBounds)
        GeoFenceArray[editIndex].figure.setMap(props.map)
      }
    }
    seteditIndex(null)
    setNewMode(false)
    setEditMode(false)
    setLinkedVehicles([])
    drawingManager?.setMap(null)
  }

  const handleToggleNew = (value: string) => () => {
    const newChecked = [...GeofenceLinkedVehicles]
    if (GeofenceLinkedVehicles?.includes(value)) {
      const index = newChecked.indexOf(value)
      newChecked.splice(index, 1)
    } else newChecked.push(value)

    setLinkedVehicles(newChecked)
  }

  // const updategeofenceVehicles = async (mode: string, geofenceid: string) => {
  //   if (GeofenceLinkedVehicles) {
  //     let result = ''
  //     let data
  //     switch (mode) {
  //       case 'none':
  //         data = { vehicles: 'none' }
  //         break
  //       case 'all':
  //         data = { vehicles: 'all' }
  //         break
  //       default:
  //         let arr = convertToStringObjectArray(GeofenceLinkedVehicles)

  //         data = { vehicles: arr }
  //         break
  //     }

  //     const promise = await updateGeofenceVehicles(
  //       data,
  //       geofenceid,
  //       organizationId,
  //       sessionToken,
  //     )
  //       .then((data: any) => {
  //         result = 'success'
  //       })
  //       .catch(() => {
  //         alert(
  //           'There was an error updating the vehicles information, please try again later',
  //         )
  //         CancelAll()
  //         result = 'failed'
  //       })

  //     return result
  //   }
  // }

  const SaveNewGeofence = () => {
    if (newBounds) {
      const rectangleToSave = new google.maps.Rectangle({
        bounds: newBounds,
        editable: false,
        draggable: false,
      })

      let tempGeofence: CustomGeofence = {
        figure: rectangleToSave,
        Geofence: GeofencefromRectangle(rectangleToSave),
      }

      if (newGeofenceName != '') {
        if (props.map) {
          saveGeofencetoApi(props.map, tempGeofence)
            .then((response) => {
              console.log('RETUNREND FROM SAVE GEOFENCE')
              console.log(response)
            })
            .catch(() => {
              alert(
                'there was an error creating the geofence, please try again later, if the error persists open a ticket',
              )
            })
        }
      } else alert('Please type a name for the new geofence')
    } else {
      alert('please draw some figure')
    }
  }

  const NewGeoNameChanged = (e: any) => {
    const str: string = e.target.value
    setsetNewgeofenceName(str)
  }
  const selectGeometry = (event: React.ChangeEvent<HTMLInputElement>) => {}

  return (
    <>
      <GeofencesList
        DrawDragGeoRectangle={DrawDragGeoRectangle}
        GeoFenceArray={GeoFenceArray}
        map={props.map}
        handleEdit={handleEdition}
        deleteGeofence={deleteGeofenceCallback}
        hideButton={editmode}
      />
      {newGeoMode && (
        <RightDrawer anchor={'right'} close={CancelAll}>
          <GeofenceNew
            CancelAll={CancelAll}
            NewGeoNameChanged={NewGeoNameChanged}
            SaveNewGeofence={SaveNewGeofence}
            selectGeometry={selectGeometry}
            handleToggleNew={handleToggleNew}
            GeofenceLinkedVehicles={GeofenceLinkedVehicles}
          />
        </RightDrawer>
      )}

      {editmode && (
        <RightDrawer anchor={'right'} close={CancelAll}>
          <Divider />
          <GeofenceEdit CancelAll={CancelAll} GeofenceToEdit={onEditGeofence} updateGeo={startUpdGeofence} />

          <Divider />
        </RightDrawer>
      )}
    </>
  )
}

export default GeofencesController
