import "./FeaturesCarousel.css"
import React, { useEffect, useState } from "react";
import FeatureChip from "../FeatureChip/index"
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useSelector } from "react-redux";

type FeaturesCarouselProps = {autoPlay:any, interval:any}
const features = [<FeatureChip bannerImage="featureRealTimeTracking.jpg" title='Real-Time Tracking' features={["Keep tabs on your entire fleet's whereabouts, 24/7, from any device."]} icon={<PinDropIcon sx={{fontSize:"50px",color:"#FF9900"}}/>}/>,
<FeatureChip bannerImage="featureHistoryTracking.jpg" title='History Tracking' features={["Go back in time and review all the tracks."]} icon={<img src="History Tracking ICON.png" width={"60px"} color="red"/>}/>,
            <FeatureChip bannerImage="featureInstantNotifications.jpg" title='Instant Notifications' features={["Stay in the know with automatic alerts for events that matter most to you."]} icon={<PhoneAndroidIcon sx={{fontSize:"50px",color:"#D11ED4"}}/>}/>,
            <FeatureChip bannerImage="featureDataDrivenReports.jpg" title='Data-Driven Reports' features={["Gain valuable insights to optimize routes, enhance efficiency, and reduce costs."]} icon={<LeaderboardIcon sx={{fontSize:"50px",color:"#10BAF0"}}/>}/>
];
const FeaturesCarousel = ({autoPlay,interval}:FeaturesCarouselProps) => {
    const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    let intervalId:any;

    if (autoPlay) {
      intervalId = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % features.length);
      }, interval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoPlay, interval, features]);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + features.length) % features.length);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % features.length);
  };

  return (
    <div className="features_carousel">
      <button onClick={handlePrev} {...(themeName==="light"?{className:"features_carousel_button"}:{className:"features_carousel_button_dark"})}>
        <span className="material-icons">arrow_left</span>
      </button>
      <div>{features[activeIndex]}</div>
      <button onClick={handleNext} {...(themeName==="light"?{className:"features_carousel_button"}:{className:"features_carousel_button_dark"})}>
        <span className="material-icons" >arrow_right</span>
      </button>
    </div>
  );
};

export default FeaturesCarousel;