import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { LastKnownData } from '../api/types'
import { updateGeolocations } from '../features/geolocations/geolocationSlice'
import { useAppDispatch } from '../app/hooks'

type NotProps = {
  message: string
}

const Notification = (props: NotProps) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50px',
        right: '100px',
        backgroundColor: 'lightblue',
        padding: '10px',
        borderRadius: '5px',
        zIndex: 10000,
      }}
    >
      {props.message}
    </div>
  )
}

const WSocket = (props: { WSdisconnectionCallback: any }) => {
  const socketURL = 'wss://hegq0f2m11.execute-api.us-east-1.amazonaws.com/development/'

  const dispatch = useAppDispatch()
  const [notification, setNotification] = useState<null | string>('TESTING WS NOTIFICATION')
  const socketRef = useRef<WebSocket | null>(null)
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const organizationId = useSelector((state: RootState) => state.session.userdata.organizationId)
  const userId = useSelector((state: RootState) => state.session.userdata.userinfo[0].userId)

  const open_msg = {
    action: 'message',
    msg: 'connection from web',
    organizationId: organizationId,
    userId: userId,
  }

  useEffect(() => {
    setupWebSocket()
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      cleanUpWebSocket()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const setupWebSocket = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      return // Already connected
    }

    socketRef.current = new WebSocket(socketURL)
    socketRef.current.addEventListener('open', onOpen)
    socketRef.current.addEventListener('message', onMessage)
    socketRef.current.addEventListener('close', onClose)
    socketRef.current.addEventListener('error', onError)

    // Initialize ping interval
    if (pingIntervalRef.current) clearInterval(pingIntervalRef.current)
    pingIntervalRef.current = setInterval(sendPing, 60000)
  }

  const cleanUpWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close()
      socketRef.current = null
    }
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current)
      pingIntervalRef.current = null
    }
  }

  const onOpen = (event: any) => {
    if (socketRef.current) socketRef.current.send(JSON.stringify(open_msg))
  }

  const onMessage = (event: any) => {
    // Handle WebSocket messages

    const data = JSON.parse(event.data)
    if (data.type === 'pong') {
      console.log('Connection is alive')
      // setPingStatus('OK')      //commented to check if performance is affected
    } else if (data.type === 'location') {
      const messageData: LastKnownData[] = JSON.parse(data.message)
      //    console.log('Parsed location data', messageData)
      dispatch(updateGeolocations(messageData))
    } else if (data.type === 'notification') {
      console.log('Notification:', data.message)
      setNotification(data.message)
      setTimeout(() => setNotification(null), 5000)
    } else {
      console.log('unkonown default type', data)
    }
  }

  const onClose = () => {
    // Attempt to reconnect or handle the close
    // ...
    console.log('WebSocketClosed')
  }

  const onError = (error: any) => {
    // Handle WebSocket error and attempt to reconnect
    // ...
  }

  const sendPing = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ action: 'ping' }))
    } else {
      setupWebSocket() // Attempt to reconnect if not open
    }
  }

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      console.log('hidden closing')
      cleanUpWebSocket()
      // Consider reducing ping frequency or closing WebSocket
    } else if (document.visibilityState === 'visible') {
      console.log('visibile reconnecting')
      setupWebSocket() // Re-establish WebSocket connection if needed
    }
  }

  return <div>{notification && <Notification message={notification} />}</div>
}

export default WSocket
