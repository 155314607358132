import { ReactElement, useState } from 'react'

import Timezonepicker from './Timezonepicker'
import { MapProps, UserSettings } from '../api/types'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../app/hooks'
import { RootState } from '../app/store'
import { updUserSettings } from '../features/usersSettings/usersettingsSlice'
import { Status, Wrapper } from '@googlemaps/react-wrapper'

import { MainMap } from './MainMap'
import {
  Button,
  Card,
  Checkbox,
  Paper,
  Switch,
  Typography,
} from '@mui/material'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import useWindowSize from '../customHooks/useWindowSize'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import './UserPreferences.css'

export const UserEmailPreferences = () => {
  const [EmailNotificationsArray, setEmailEnabled] = useState<string[]>([])

  const [SMSNotificationsArray, setSMSEnabled] = useState<string[]>([])

  const handleEmailEnable = (value: string) => () => {
    const newChecked = [...EmailNotificationsArray]

    if (EmailNotificationsArray?.includes(value)) {
      const index = newChecked.indexOf(value)
      newChecked.splice(index, 1)
    } else newChecked.push(value)

    setEmailEnabled(newChecked)
  }

  const handleSMSEnable = (value: string) => () => {
    const newChecked = [...SMSNotificationsArray]

    if (SMSNotificationsArray?.includes(value)) {
      const index = newChecked.indexOf(value)
      newChecked.splice(index, 1)
    } else newChecked.push(value)

    setSMSEnabled(newChecked)
  }

  return (
    <div style={{ marginBottom: 0 }}>
      <label style={{ marginLeft: 100 }}>SMS Email</label>
      <div>
        <label>Geofence </label>
        <Switch
          onChange={handleEmailEnable('geofences')}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={EmailNotificationsArray?.includes('geofences')}
          value={EmailNotificationsArray.includes('geofences') ? true : false}
        />
        <Switch
          onChange={handleSMSEnable('geofences')}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={SMSNotificationsArray?.includes('geofences')}
          value={SMSNotificationsArray.includes('geofences') ? true : false}
        />
      </div>
      <div>
        <label>Ignition </label>
        <Switch
          onChange={handleEmailEnable('ignition')}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={EmailNotificationsArray?.includes('ignition')}
          value={EmailNotificationsArray.includes('ignition') ? true : false}
        />
        <Switch
          onChange={handleSMSEnable('ignition')}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={SMSNotificationsArray?.includes('ignition')}
          value={SMSNotificationsArray.includes('ignition') ? true : false}
        />
      </div>
      <div>
        <label>Motion </label>
        <Switch
          onChange={handleEmailEnable('motion')}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={EmailNotificationsArray?.includes('motion')}
          value={EmailNotificationsArray.includes('motion') ? true : false}
        />
        <Switch
          onChange={handleSMSEnable('motion')}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={SMSNotificationsArray?.includes('motion')}
          value={SMSNotificationsArray.includes('motion') ? true : false}
        />
      </div>
    </div>
  )
}

export type secureUserSettings = {
  userSet: UserSettings
  token: string
  organizationId: string
}
const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY

const marstyle = { marginLeft: '10px' }

const UserPreferences = (props: UserSettings) => {

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const livegeolocationstatus = useSelector(
    (state: RootState) => state.geolocations.status,
  )
  const render = (status: Status): ReactElement => {
    return <h1>{status}</h1>
  }

  const dispatch = useAppDispatch()
  const [emailNotificationsGeofence, setEmailNotificationsGeofence] = useState(
    props.emailNotificationsGeofence,
  )
  const [emailNotificationsIgnition, setEmailNotificationsIgnition] = useState(
    props.emailNotificationsIgnition,
  )
  const [emailNotificationsMotion, setEmailNotificationsMotion] = useState(
    props.emailNotificationsMotion,
  )
  const [smsNotificationsGeofence, setSmsNotificationsGeofence] = useState(
    props.smsNotificationsGeofence,
  )
  const [smsNotificationsIgnition, setSmsNotificationsIgnition] = useState(
    props.smsNotificationsIgnition,
  )
  const [smsNotificationsMotion, setSmsNotificationsMotion] = useState(
    props.smsNotificationsMotion,
  )

  const error = useSelector((state: RootState) => state.usersettings.error)
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const userid = useSelector(
    (state: RootState) => state.session.userdata.idtoken['userid'],
  )
  const userSettings = useSelector(
    (state: RootState) => state.usersettings.usersettings,
  )

  const liveGeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )

  const map_styles = ['default', 'silver', 'retro', 'night', 'hiding']

  const [distanceUnit, setDistanceunits] = useState(props.distanceUnit)
  const [distanceUnitCheck, setDistanceunitCheck] = useState(() => {
    return props.distanceUnit == 'km' ? true : false
  })
  const [speedUnit, setspeedunits] = useState(props.speedUnit)
  const [speedUnitCheck, setspeedUnitCheck] = useState(() => {
    return props.speedUnit == 'km' ? true : false
  })
  const [timezone, setTimezone] = useState(props.timezone)
  const [mapOverlay, setMapOverlay] = useState(props.mapOverlay)

  const [MapCheckedType, setCheckedState] = useState<boolean[]>(
    map_styles.map((item, index) => {
      return item === props.mapOverlay ? true : false
    }),
  )
  const size = useWindowSize()

  const handleMaptypeChange = (e: any) => {
    // e.preventDefault()
    setMapOverlay(e.target.value)
  }

  const DistanceUnitChange = (e: { target: { value: any } }) => {
    if (e.target.value === 'km') setDistanceunitCheck(true)
    else setDistanceunitCheck(false)
    setDistanceunits(e.target.value)
  }

  const SpeedUnitChange = (e: { target: { value: any } }) => {
    if (e.target.value === 'km') setspeedUnitCheck(true)
    else setspeedUnitCheck(false)
    setspeedunits(e.target.value)
  }
  const UpdateSettings = () => {
    let updatedUserSettings = { ...userSettings }
    updatedUserSettings.distanceUnit = distanceUnit
    updatedUserSettings.speedUnit = speedUnit
    updatedUserSettings.mapOverlay = mapOverlay
    updatedUserSettings.timezone = timezone
    updatedUserSettings.emailNotificationsGeofence = emailNotificationsGeofence
    updatedUserSettings.emailNotificationsIgnition = emailNotificationsIgnition
    updatedUserSettings.emailNotificationsMotion = emailNotificationsMotion
    updatedUserSettings.smsNotificationsGeofence = smsNotificationsGeofence
    updatedUserSettings.smsNotificationsIgnition = smsNotificationsIgnition
    updatedUserSettings.smsNotificationsMotion = smsNotificationsMotion

    const secSettings: secureUserSettings = {
      userSet: updatedUserSettings,
      token: sessionToken,
      organizationId: organizationId,
    }
    dispatch(updUserSettings(secSettings))
  }

  const mapProps: MapProps = {
    center: { lat: 26.25979, lng: -80.08094 }, // algorithm with all geolocations to center I would say mean point of max and min lat an long
    zoom: 8,
    height: '300px',
    width: '100%',
    cssstyle: 'map2',
    mapstyle: mapOverlay!,
    enable_controller: false,
  }
  const container = {
    display: 'flex',
  }
  return (
    <div style={{overflow:"hidden"}} className="UserPreferences--MainContainer">
      <h2>User Settings</h2>
      <div  className="UserPreferences--SettingsContainer">
      <Accordion onChange={handleChange('setting4')} expanded={expanded === 'setting4'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Map Style</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={mapOverlay}
                onChange={handleMaptypeChange}
              >
                {map_styles.map((item, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  )
                })}
              </RadioGroup>
              <div style={{ width: 400, height: 290,overflow:"hidden" }}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300,
                  }}
                >
                  {/* <Chart /> */}
                  <Wrapper apiKey={REACT_APP_API_KEY!} render={render}>
                    <MainMap {...mapProps}>map</MainMap>
                  </Wrapper>
                </Paper>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={handleChange('setting1')} expanded={expanded === 'setting1'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Speed Units</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={marstyle}>
              <input
                value={'mile'}
                type="checkbox"
                onChange={DistanceUnitChange}
                checked={!distanceUnitCheck}
              />{' '}
              mile
            </div>
            <div style={marstyle}>
              <input
                value={'km'}
                type="checkbox"
                onChange={DistanceUnitChange}
                checked={distanceUnitCheck}
              />{' '}
              kilometer
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={handleChange('setting2')} expanded={expanded === 'setting2'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Distance Units</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={marstyle}>
              <input
                value={'mile'}
                type="checkbox"
                onChange={SpeedUnitChange}
                checked={!speedUnitCheck}
              />{' '}
              mile
            </div>
            <div style={marstyle}>
              <input
                value={'km'}
                type="checkbox"
                onChange={SpeedUnitChange}
                checked={speedUnitCheck}
              />{' '}
              kilometer
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={handleChange('setting3')} expanded={expanded === 'setting3'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Time Zone</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={marstyle}>
              <Timezonepicker />
            </div>
          </AccordionDetails>
        </Accordion>
        
        <Accordion onChange={handleChange('setting5')} expanded={expanded === 'setting5'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Notification Preferences
          </AccordionSummary>
          <AccordionDetails>
            <div>email</div>
            <div>
              <label>Geofence </label>
              <Switch
                onChange={() =>
                  setEmailNotificationsGeofence(!emailNotificationsGeofence)
                }
                inputProps={{ 'aria-label': 'controlled' }}
                checked={emailNotificationsGeofence}
                value={emailNotificationsGeofence}
              />
            </div>
            <div>
              <label>Ignition </label>
              <Switch
                onChange={() =>
                  setEmailNotificationsIgnition(!emailNotificationsIgnition)
                }
                inputProps={{ 'aria-label': 'controlled' }}
                checked={emailNotificationsIgnition}
                value={emailNotificationsIgnition}
              />
            </div>
            <div>
              <label>Motion </label>
              <Switch
                onChange={() =>
                  setEmailNotificationsMotion(!emailNotificationsMotion)
                }
                inputProps={{ 'aria-label': 'controlled' }}
                checked={emailNotificationsMotion}
                value={emailNotificationsMotion}
              />
            </div>
            <div>
              push notifications
              <div>
                Geofence{' '}
                <Switch
                  onChange={() =>
                    setSmsNotificationsGeofence(!smsNotificationsGeofence)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={smsNotificationsGeofence}
                  value={smsNotificationsGeofence}
                />
              </div>
              <div>
                {' '}
                Ignition{' '}
                <Switch
                  onChange={() =>
                    setSmsNotificationsIgnition(!smsNotificationsIgnition)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={smsNotificationsIgnition}
                  value={smsNotificationsIgnition}
                />
              </div>
              <div>
                Motion{' '}
                <Switch
                  onChange={() =>
                    setSmsNotificationsMotion(!smsNotificationsMotion)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={smsNotificationsMotion}
                  value={smsNotificationsMotion}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className='UserPreferences--ActionButtonsContainer'>
        <Button
          variant="outlined"
          onClick={UpdateSettings}
          style={{ marginTop: 10, marginRight: 10,maxHeight:"50px" }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          onClick={() => alert('Your changes have been disregarded')}
          style={{ marginTop: 10,maxHeight:"50px" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default UserPreferences
