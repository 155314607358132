import { useContext, useEffect, useState } from 'react'
import { Driver, VehicleControllerProps } from '../api/types'
import VehicleListItem from './VehicleListItem'
// import '../styles/tracking-styles.css'
import { selectAllVehicles } from '../features/vehicles/vehicleSlice'
import { useSelector } from 'react-redux'
import * as React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { SxProps } from '@mui/system'
import { selectAllDrivers } from '../features/drivers/driverSlice'
import { Divider, List } from '@mui/material'

import { RootState } from '../app/store'
import useWindowSize from '../customHooks/useWindowSize'
import { Container } from 'react-bootstrap'

export const VehiclesControllers = (props: VehicleControllerProps) => {
  const vehicles = useSelector(selectAllVehicles)

  const [searchTerm, setSearchterm] = useState<string>('')
  const [selected, setSelected] = useState<string>('')
  const windowSize = useWindowSize()
  const alldrivers = useSelector(selectAllDrivers)
  const Data = useSelector((state: RootState) => state.geolocations.geolocations)

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll('.sub-menu').forEach((el) => {
      el.classList.remove('active')
    })
  }
  useEffect(() => {
    let menuItems = document.querySelectorAll('.track-list-item')
    menuItems.forEach((el) => {
      el.addEventListener('click', (e) => {
        const next = el.nextElementSibling
        removeActiveClassFromSubMenu()
        menuItems.forEach((el) => el.classList.remove('active'))
        el.classList.toggle('active')

        if (next !== null) {
          next.classList.toggle('active')
        }
      })
    })
  }, [])

  const handleSearchChange = (val: any) => {
    //TODO find out why there is not target after specify type
    const str: string = val.target.value
    setSearchterm(str)
  }
  const updateSelected = (item: string) => {
    setSelected(item)
  }

  // const styles: SxProps = {
  //   position: 'absolute',
  //   top: 28,
  //   right: 0,
  //   left: 0,
  //   zIndex: 1,
  //   border: '1px solid',
  //   p: 1,
  //   bgcolor: 'background.paper',
  // }

  let allDevicesString: string[] = []

  Data.forEach((value, index) => {
    allDevicesString.push(value.deviceUniqueId)
  })
  //TODO irmpove this
  return (
    <Container
      style={{
        marginTop: 4,
        padding: 0,
      }}
    >
      <input
        type="text"
        placeholder="Search all drivers and vehicles"
        onChange={handleSearchChange}
        style={{
          marginLeft: 6,
          minHeight: 37,
          width: '95%',
          borderRadius: 5,
          borderWidth: 1,
        }}
      />
      {/* #TODO  Filters*/}

      <Divider />

      <List
        style={{
          padding: 0,
          width: '100%',
          maxHeight: windowSize.height - 200, // Adjust this value as needed
          overflow: 'auto',
          backgroundColor: '#FFF', //TODO theme
        }}
      >
        {allDevicesString
          .filter((val) => {
            const matchingVehicle = vehicles.find((vehic) => vehic.device?.id === val)

            if (searchTerm === '') {
              return val
            } else if (matchingVehicle?.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return val
            } else if (matchingVehicle?.driver?.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return val
            } else return null
          })

          .map((item, index) => {
            const matchingVehicle = vehicles.find((vehic) => vehic.device?.id === item)

            return (
              <React.Fragment key={index}>
                <VehicleListItem
                  key={index}
                  vehicle={matchingVehicle}
                  driver={alldrivers.find(
                    //need to grab if from this reducer otherwise wont see the udpate if you grab it from vehicle
                    (driver: Driver) => driver.id === matchingVehicle?.driver?.id,
                  )}
                  device={item}
                  selected={selected}
                  zoomin={() => {
                    props.onclickHandler(item)
                    updateSelected(item)
                  }}
                  clickHistory={props.historyClick}
                  clickInfo={props.infoClick}
                />
                <Divider style={{ height: 1, backgroundColor: '#000' }} />
              </React.Fragment>
            )
          })}
      </List>
    </Container>
  )
}

export default VehiclesControllers
