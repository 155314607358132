import { configureStore } from '@reduxjs/toolkit'
import { AnyAction, CombinedState, combineReducers } from 'redux'
import vehiclesSlice from '../features/vehicles/vehicleSlice'
import usersSlice from '../features/drivers/driverSlice'
import sessionSlice from '../features/session/sessionSlice'
import deviceSlice from '../features/devices/deviceSlice'
import geolocationSlice from '../features/geolocations/geolocationSlice'
import histgeolocationSlice from '../features/geolocations/histgeolocationSlice'
import userSettingsSlice from '../features/usersSettings/usersettingsSlice'
import userSlice from '../features/users/userSlice'
import geofenceSlice from '../features/geofences/geofenceSlice'
import devicedataslice from '../features/deviceData/devicedataSlice'
//import { apiSlice } from '../features/apislice/apiSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // You can choose different storage engines based on your platform (localStorage, AsyncStorage, etc.)
import {
  Vehicle,
  Driver,
  User,
  RdxStateSession,
  Device,
  Dict,
  UserSettings,
  Geofence,
} from '../api/types'
//import rootReducer from './rootReducer'

//BY YEYI
import selectedThemeReducer from '../../src/yeyi/redux/slices/ThemeSelector'

const persistConfig = {
  key: 'root', // key is a unique identifier for your storage, could be anything
  storage, // the storage engine you're using
  // Optionally, you can whitelist or blacklist specific reducers:
  // whitelist: ['session', 'vehicles', 'devices', 'session'], // reducers to persist
  blacklist: ['vehicles', 'drivers', 'users'], // reducers not to persist
}

const appReducer = combineReducers({
  vehicles: vehiclesSlice,
  drivers: usersSlice,
  users: userSlice,
  session: sessionSlice,
  devices: deviceSlice,
  geolocations: geolocationSlice,
  histgeolocations: histgeolocationSlice,
  usersettings: userSettingsSlice,
  geofences: geofenceSlice,
  devicedata: devicedataslice,
  selectedThemeReducer,
})

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'session/deauthenticate') {
    console.log(' VAMOS A VWE SI sale bien')
    localStorage.removeItem('persist:root')
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})
let persistor = persistStore(store)

export { persistor }
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
