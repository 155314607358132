import axios from 'axios'
import { Dict } from './types'
import { URL } from './urls'

import { Response } from './api_functions'
const BASE_URL = URL.URl_ALL

const f = (r: any): Dict => JSON.parse(r)

export const getSharedAll = async <T,>(
  url: string,
  share: string,
): Promise<T[]> => {
  return new Promise((res) => {
    res(
      axios
        .get<Response<T>>(`${BASE_URL}/${url}`, {
          params: { share },
          headers: {
            Accept: 'application/json',
          },
        })
        .then((response) => {
          return response.data.result
        })
        .catch((reason): T[] => {
          throw reason
        }),
    )
  })
}

export const shareItem = async <T,>(
  url: string,
  item: T,
  sessionToken: string,
  organizationId: string,
): Promise<any> => {
  return new Promise((res) => {
    res(
      axios
        .post<T>(`${BASE_URL}/${url}`, item, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          throw reason
        }),
    )
  })
}
