import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import {
  getGeoLocations,
  getDevicesLatestGeoLocations,
} from '../../api/geoLocation'
import { Dict, secureCompany } from '../../api/types'

let histgeolocations: Dict = {}

export type hthunkprops = {
  company: secureCompany
  deviceID: string | undefined | null
  startDate: Date | null
  endDate: Date | null
}

let initialState = {
  histgeolocations,
  status: 'idle',
  error: '',
}

export const fetchHistGeolocations = createAsyncThunk(
  'geolocation/fetchHistGeolocations',
  async (obj: hthunkprops) => {
    const result = await getGeoLocations(
      obj.company.organizationId,
      obj.deviceID,
      obj.startDate,
      obj.endDate,
      obj.company.token,
    )
    return result
  },
)

const geoLocationsSlice = createSlice({
  name: 'geolocationSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        fetchHistGeolocations.pending,
        (state, action: PayloadAction) => {
          state.status = 'loading'
        },
      )
      .addCase(fetchHistGeolocations.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.histgeolocations = action.payload
      })
      .addCase(fetchHistGeolocations.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'historical geolocations could not be retrieved'
        //TODO  state.error = action.error.message
        //
      })
  },
})

export default geoLocationsSlice.reducer

export const selectAllHistGeolocations = (state: {
  histgeolocations: { geo: Dict }
}) => state.histgeolocations
