import React, { useState } from 'react'
import './LandingPageMobileMenu.css'
import MenuIcon from '@mui/icons-material/Menu'
import DarkLightModeSelector from '../DarkLightModeSelector/DarkLightModeSelector'
import LandingPageLogin from '../LandingPageLogin/LandingPageLogin'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux'

type LandingPageMobileMenuProps = {
  contactUsModalShowHandler:any
}
const LandingPageMobileMenu = ({contactUsModalShowHandler}:LandingPageMobileMenuProps) => {
  /**------------------------------------------------------ */
  /** STATE VARIABLES HERE                                  */
  /**------------------------------------------------------ */
  const [productsExpanded, setProductsExpanded] = useState(false)

  /**------------------------------------------------------ */
  /** HANDLERS HERE                                         */
  /**------------------------------------------------------ */
  const toggleProductsMenuExpanded = () => {
    setProductsExpanded((prevState) => !prevState)
  }

  /**------------------------------------------------------ */
  /** STATE SELECTORS HERE                                  */
  /**------------------------------------------------------ */
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)

  return (
    <div className="landing_page_mobile_menu--main_container">
      <DarkLightModeSelector />
      <LandingPageLogin status={true} />
      <div>
        <div className="landing_page_mobile_menu--dropdown">
          <MenuIcon {...(themeName==="light"?{sx:{color:"black"}}:{sx:{color:"white"}})} />
          <div className="landing_page_mobile_menu--dropdown-content">
            <div className="landing_page_mobile_menu--option_list_container">
              <ul>
                <li>
                  <div
                    className="landing_page_mobile_menu--item"
                    onClick={toggleProductsMenuExpanded}
                  >

                    PRODUCTS
                    {productsExpanded === true?<ExpandLessIcon/>:<ExpandMoreIcon/>}
                    
                  </div>
                </li>
                {productsExpanded === true && (
                  <div>
                    <div className="landing_page_mobile_menu--sub_item">
                      Tracking
                    </div>
                    <div className="landing_page_mobile_menu--sub_item">
                      Logbook
                    </div>
                    <div className="landing_page_mobile_menu--sub_item">
                      Dispatching
                    </div>
                  </div>
                )}
                <li>
                  <div className="landing_page_mobile_menu--item" onClick={contactUsModalShowHandler}>
                    CONTACT US
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageMobileMenu
