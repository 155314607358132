import React, { useEffect, useState } from 'react'

import List from '@mui/material/List'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'

import ListItemText from '@mui/material/ListItemText'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import {
  Avatar,
  Checkbox,
  CircularProgress,
  ListItemAvatar,
} from '@mui/material'
import { CustomGeofence } from '../../api/types'
import { selectAllVehicles } from '../../features/vehicles/vehicleSlice'
import { useSelector } from 'react-redux'
import useWindowSize from '../../customHooks/useWindowSize'

import {
  getGeofencesVehicles,
  updateGeofenceVehicles,
} from '../../api/geofences'

import { RootState } from '../../app/store'

export type GeofenceEditprops = {
  CancelAll: (e: any) => void
  updateGeo: (editedname: string) => void
  GeofenceToEdit: CustomGeofence | undefined
}

const GeofenceEdit = (props: GeofenceEditprops) => {
  const [isLoading, setLoading] = useState(true)

  const [vehiclesListStatus, setVehiclesListStatus] = useState(false)
  const [editedGeofenceName, setsetNewgeofenceName] = React.useState(
    props.GeofenceToEdit?.Geofence?.name,
  )
  const [GeofenceLinkedVehicles, setLinkedVehicles] = React.useState<string[]>(
    [],
  )
  const windowSize = useWindowSize()

  const NewGeoNameChanged = (e: any) => {
    const str: string = e.target.value
    setsetNewgeofenceName(str)
  }
  const vehicles = useSelector(selectAllVehicles)
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )

  const selectGeometry = () => {}

  const handleClose = () => {
    //TODO
  }
  const handleToggle = (value: string) => () => {
    const newChecked = [...GeofenceLinkedVehicles]
    if (GeofenceLinkedVehicles?.includes(value)) {
      const index = newChecked.indexOf(value)
      newChecked.splice(index, 1)
    } else newChecked.push(value)

    setLinkedVehicles(newChecked)
    if (!vehiclesListStatus) setVehiclesListStatus(true)
  }

  //esto esta rarisimo todo

  function convertToStringObjectArray(stringArray: string[]): { id: string }[] {
    return stringArray.map((element) => ({ id: element }))
  }

  const fetchgeofenceVehicle = async () => {
    const promise = await getGeofencesVehicles(
      props.GeofenceToEdit?.Geofence?.id || '',
      organizationId,
      sessionToken,
    )
      .then((data: string[]) => {
        setLinkedVehicles(data)
        setLoading(false)
      })
      .catch(() => {
        alert(' there was an error retrivieng information, please try again.')
        props.CancelAll('review')
      })
  }

  const updategeofenceVehicles = async (mode: string) => {
    let result = ''
    let data
    switch (mode) {
      case 'none':
        data = { vehicles: 'none' }
        break
      case 'all':
        data = { vehicles: 'all' }
        break
      default:
        let arr = convertToStringObjectArray(GeofenceLinkedVehicles)

        data = { vehicles: arr }
        break
    }

    const promise = await updateGeofenceVehicles(
      data,
      props.GeofenceToEdit?.Geofence.id!,
      organizationId,
      sessionToken,
    )
      .then((data: any) => {
        result = 'success'
      })
      .catch(() => {
        alert(
          'There was an error updating the vehicles information, please try again later',
        )
        props.CancelAll('review')
        result = 'failed'
      })

    return result
  }

  useEffect(() => {
    fetchgeofenceVehicle()
  }, [])
  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div style={{ marginLeft: 10 }}>
      <label>Name </label>
      <input
        style={{ marginLeft: 10 }}
        value={editedGeofenceName}
        type="text"
        onChange={NewGeoNameChanged}
      />

      <Typography style={{ marginTop: 10 }}>Geofence type</Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="Rectangle"
        name="radio-buttons-group"
        onChange={selectGeometry}
      >
        <FormControlLabel
          value="Rectangle"
          control={<Radio />}
          label="Rectangle"
        />
      </RadioGroup>
      <Typography style={{ marginTop: 20 }}>Alert Type</Typography>
      <RadioGroup
        aria-labelledby="onentrance"
        defaultValue="onentrance"
        name="radio-buttons-group"
      >
        <FormControlLabel
          value="onentrance"
          control={<Radio />}
          label="On Entrance"
        />
        <FormControlLabel value="onexit" control={<Radio />} label="On Exit" />
        <FormControlLabel value="onboth" control={<Radio />} label="On Both" />
      </RadioGroup>

      <List
        dense
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          overflow: 'scroll',
          height: windowSize.height / 2.5,
        }}
      >
        {vehicles.map((item, index) => {
          return (
            <ListItem
              key={index}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggle(item.id!)} //TODO
                  inputProps={{ 'aria-labelledby': item.name }}
                  checked={GeofenceLinkedVehicles?.includes(item.id!)}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${index + 1}`}
                    // src={iconbaseurl + item.icon}
                  />
                </ListItemAvatar>
                <ListItemText id={item.name} primary={`${item.name} `} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>

      <Button
        onClick={() => {
          if (vehiclesListStatus) {
            updategeofenceVehicles('')
          }
          if (editedGeofenceName) props.updateGeo(editedGeofenceName)
        }}
      >
        Update
      </Button>
      <Button onClick={props.CancelAll}>Cancel</Button>
    </div>
  )
}

export default GeofenceEdit
