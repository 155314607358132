import React, { useState, useEffect } from 'react'
import '../../styles/Login.css'
import CssBaseline from '@mui/material/CssBaseline'
import { reasons } from './cogAccount'
import { setCredentials } from './sessionSlice'

import { authenticate, getSession } from './cogAccount'
import {
  InviteRole,
  RdxStateSession,
  secureUserCompany,
  UsersInfo,
} from '../../api/types'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import {
  CognitoUser,
  CognitoUserSession,
  IAuthenticationCallback,
} from 'amazon-cognito-identity-js'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import { fetchUserSettings } from '../usersSettings/usersettingsSlice'
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { RootState } from '../../app/store'
import { Navigate } from 'react-router-dom'
import useWindowSize from '../../customHooks/useWindowSize'
import './SessionLogin.css'
import { useSelector } from 'react-redux'

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://www.vermmis.com/">
        Vermmis
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const theme = createTheme()

const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault()
  const data = new FormData(event.currentTarget)
}

const SessionLogin = () => {
  const navigate = useNavigate()
  /** BY YEYI */
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  const handleShowPasswordClick = (e: any) => {
    e.stopPropagation()
    setPasswordShown(!passwordShown)
  }
  const handleVermmisLogoClick = () => {
    navigate('/')
  }

  let [searchParams, setSearchParams] = useSearchParams()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [organization, setOrganization] = useState('')
  const [role, setRole] = useState<InviteRole[]>([])
  const [changePass, setChangePass] = useState(false)
  const [rawdata, setrawdata] = useState<any>()
  const [rolePicker, setRolePicker] = useState(false)
  const [enablesave, setEnableSave] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [duplicate, setDuplicate] = useState('')
  const [userAttributes, setUserAttributes] = useState()
  const [coguser, setCogUser] = useState<CognitoUser>()
  const [errMsg, setErrMsg] = useState('')
  const [tempUsersData, settempUD] = useState<UsersInfo[]>([])
  const [passwordShown, setPasswordShown] = useState(false)

  const [conditionsArray, setCondsArr] = useState<Boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ])

  const dispatch = useAppDispatch()
  const sessionStatus = useSelector((state: RootState) => state.session.status)
  //

  let params = '?'
  searchParams.forEach((value, key) => {
    params += key + '=' + value + '&'
  })

  //password validation repeated for quick response before on blur do not delete
  useEffect(() => {
    if (duplicate !== newPassword) {
      setEnableSave(false)
    } else {
      setEnableSave(true)
    }
  }, [newPassword, duplicate])
  function checkLenght(str: string) {
    const tempArr = conditionsArray
    if (str.length >= 8) tempArr[0] = true
    else tempArr[0] = false
    setCondsArr(tempArr)
  }

  const handleEmailBlur = () => {
    // Remove trailing spaces
    const trimmedEmail = email.trim()
    setEmail(trimmedEmail)
  }

  function lowerPat(str: string) {
    const pattern = new RegExp('[a-z]')
    const tempArr = conditionsArray
    if (pattern.test(str)) tempArr[1] = true
    else tempArr[1] = false
    setCondsArr(tempArr)
  }
  function upperCase(str: string) {
    const pattern = new RegExp('[A-Z]')
    const tempArr = conditionsArray
    if (pattern.test(str)) tempArr[2] = true
    else tempArr[2] = false
    setCondsArr(tempArr)
  }
  function numero(str: string) {
    const pattern = new RegExp('[1-9]')
    const tempArr = conditionsArray
    if (pattern.test(str)) tempArr[3] = true
    else tempArr[3] = false

    setCondsArr(tempArr)
  }
  function specialChar(str: string) {
    const pattern = new RegExp('[-+_!@#$%^&*.,?]')
    const tempArr = conditionsArray
    if (pattern.test(str)) tempArr[4] = true
    else tempArr[4] = false
    setCondsArr(tempArr)
  }
  const password_requirements: string[] = [
    '8-character minimum length',
    'Contains at least 1 lowercase letter',
    'Contains at least 1 uppercase letter',
    'Contains at least 1 number',
    `Contains at least 1 special character $ * . [ ] { } ( ) ? - " ! @ # % & / , > < ' : ; | _ ~  + ="`,
  ]

  const fetchSession = () => {
    getSession()
      .then((data: any) => {
        const session: CognitoUserSession = data['session']
        const idToken = session.getIdToken().decodePayload()

        const userdata: UsersInfo[] = JSON.parse(idToken['users'])

        // validating multiOrganization and Multirole
        if (userdata.length > 1) {
          settempUD(userdata)
          setRolePicker(true)
        }
        //leave it like this
        else {
          settempUD(userdata)
          if (userdata[0].roles.length > 1) setRolePicker(true)
          else {
            console.log('single Organization and role')
            setOrganization(userdata[0].organizationId)
            setRole(userdata[0].roles)

            localStorage.setItem('organizationId', organization)
            localStorage.setItem('role', JSON.stringify(role))

            proceed(data)
          }
        }
        setrawdata(data)
      })
      .catch((e: any) => {
        alert('session could not be fetched')
      })
  }
  const login = (e: any) => {
    e.preventDefault()

    authenticate(email, password)
      .then((data: any) => {
        const sessionInfo = fetchSession()

        //navigate(location.pathname + location.search)
      })
      .catch((reason: reasons) => {
        setErrMsg(reason.error)

        if (reason.error === 'changepass') {
          setChangePass(true)
          setCogUser(reason.user)

          setUserAttributes(reason.userAtt)
        } else {
          alert(reason)
        }
      })
  }
  const passworChallengeCallback: IAuthenticationCallback = {
    onSuccess: (
      session: CognitoUserSession,
      userConfirmationNecessary?: boolean,
    ) => {
      alert('Password changed Sucessfully')
      setChangePass(false)
    },
    onFailure: (err: any) => {
      alert('there was an error' + err)
    },
  }

  let handleSave = () => {
    coguser?.completeNewPasswordChallenge(
      newPassword,
      userAttributes,
      passworChallengeCallback,
    )
  }
  const handleCloseDialog = () => {
    setRolePicker(false)
  }
  const selectRole = (e: any) => {
    e.preventDefault()
    setRole(e.target.value)
  }

  const selectOrganization = (e: any) => {
    e.preventDefault()
    setOrganization(e.target.value)
  }

  const proceed = (data: any) => {
    const session: CognitoUserSession = data['session']
    const idToken = session.getIdToken().decodePayload()

    const usersinfo: UsersInfo[] = JSON.parse(idToken['users'])

    const sessionUserdata: RdxStateSession = {
      username: data['user']['username'],
      accesstoken: session.getAccessToken().getJwtToken(),
      userinfo: usersinfo,
      idtoken: idToken,
      profile: data['profile'],
      organizationId:
        organization === '' ? usersinfo[0].organizationId : organization,
      // role: role === '' ? usersinfo[0].roles[0] : role,
      role: usersinfo[0].roles,
    }
    //  localStorage.setItem('organizationId', organization)
    // localStorage.setItem('role', role)
    // *********testing*************************************TODO WIP
    dispatch(setCredentials(sessionUserdata))
    const secUser: secureUserCompany = {
      token: sessionUserdata.accesstoken,
      organizationId:
        organization === '' ? usersinfo[0].organizationId : organization,
      userId: usersinfo[0].userId,
    }

    dispatch(fetchUserSettings(secUser))
    // *********testing*************************************TODO WIP
    navigate('/tracking')
  }

  const validatePassword = (e: any) => {
    setNewPassword(e.target.value)
    if (duplicate !== newPassword) {
      setEnableSave(false)
    } else {
      setEnableSave(true)
    }
    lowerPat(e.target.value)
    upperCase(e.target.value)
    numero(e.target.value)
    checkLenght(e.target.value)
    specialChar(e.target.value)
  }

  /**----------------------------------------------------------*/
  /** COMPONENT RETURN HERE                                    */
  /**----------------------------------------------------------*/
  return (
    <div className="SessionLogin--MainContainer">
      {/*-----------------------------------------------------*/}
      {/*SESSION LOGIN LEFT  CONTAINER                        */}
      {/*-----------------------------------------------------*/}
      <div
        {...(themeName === 'light'
          ? { className: 'SessionLogin--LeftContanier' }
          : { className: 'SessionLogin--LeftContanierDark' })}
      >
        <div className="SessionLogin--LogoContainer">
          <img
            className="SessionLogin--Logo"
            src="Company_Logo.png"
            alt="Vermmis Logo"
            onClick={handleVermmisLogoClick}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="SessionLogin--TitleSubtitleContainer">
          <p
            {...(themeName === 'light'
              ? { className: 'SessionLogin--Title' }
              : { className: 'SessionLogin--TitleDark' })}
          >
            Fleet Manager
          </p>
          <p
            {...(themeName === 'light'
              ? { className: 'SessionLogin--Subtitle' }
              : { className: 'SessionLogin--SubtitleDark' })}
          >
            Effortless Fleet Management at Your Fingertips
          </p>
        </div>
      </div>
      {/*-----------------------------------------------------*/}
      {/*SESSION LOGIN LEFT CONTAINER ENDS HERE               */}
      {/*-----------------------------------------------------*/}

      {/*-----------------------------------------------------*/}
      {/*SESSION LOGIN RIGHT CONTAINER     HERE               */}
      {/*-----------------------------------------------------*/}
      <div className='SessionLogin--RightContanier'>
        {!changePass?(<>
        {rolePicker ? (
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              //alignItems: 'center',
              //width: 'fit-content',
              //position: 'absolute',
              //left: 0, //Change by yeyi ;)
              backgroundColor: 'white', //added by Yeyi
            }}
          >
            <label>Organization</label>
            {tempUsersData.length > 1 && (
              <select onChange={selectOrganization}>
                {tempUsersData.map((item: UsersInfo, index: number) => {
                  return (
                    <option key={index} value={item.organizationId}>
                      {item.organizationId}
                    </option>
                  )
                })}
                <option> </option>
              </select>
            )}

            {tempUsersData[0].roles.length > 1 && (
              <>
                <label>Role</label>
                <select onChange={selectRole}>
                  {tempUsersData[0].roles.map(
                    (item: InviteRole, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    },
                  )}
                  <option> </option>
                </select>
              </>
            )}

            <Button variant="contained" onClick={() => proceed(rawdata)}>
              GO
            </Button>
          </Box>
        ) : sessionStatus === 'succeeded' ? (
          <Navigate to="/tracking" replace />
        ) : (
          // <<<<<<< web_scokets
          //           <form onSubmit={login}>
          //             <label>{errMsg}</label>
          //             <Box
          //               sx={{
          //                 marginTop: 0, //Change By Yeyi
          //                 display: 'flex',
          //                 flexDirection: 'column',
          //                 alignItems: 'center',
          //                 width: 'fit-content',
          //                 position: 'absolute',
          //                 left: 0, //Change by yeyi ;)
          //                 backgroundColor: 'white', //Added by Yeyi
          //                 padding: 2, //Added by Yeyi
          //               }}
          //             >
          //               <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          //                 <LockOutlinedIcon />
          //               </Avatar>
          //               <Typography component="h1" variant="h5">
          //                 Sign in
          //               </Typography>
          //               <TextField
          //                 margin="normal"
          //                 required
          //                 fullWidth
          //                 id="email"
          //                 label="Email Address"
          //                 name="email"
          //                 autoComplete="email"
          //                 autoFocus
          //                 value={email}
          //                 onChange={(event) => setEmail(event.target.value)}
          //                 onBlur={handleEmailBlur}
          //               />

          //               <TextField
          //                 margin="normal"
          //                 required
          //                 fullWidth
          //                 name="password"
          //                 label="Password"
          //                 id="password"
          //                 autoComplete="current-password"
          //                 type={passwordShown ? 'text' : 'password'}
          //                 value={password}
          //                 onChange={(e: any) => setPassword(e.target.value)}
          //                 InputProps={{
          //                   endAdornment: (
          //                     <InputAdornment position="end">
          //                       <IconButton onClick={handleShowPasswordClick}>
          //                         {passwordShown ? <VisibilityOff /> : <Visibility />}
          //                       </IconButton>
          //                     </InputAdornment>
          //                   ),
          //                 }}
          //               />

          //               <Button
          //                 type="submit"
          //                 fullWidth
          //                 variant="contained"
          //                 sx={{ mt: 3, mb: 2 }}
          //                 onClick={login}
          // =======
          <div>
            
              <form
                onSubmit={login}
                style={{ display: 'flex', height: 'fit-content' }}
              >
                <label>{errMsg}</label>

                <Box
                  sx={{
                    marginTop: 0, //Change By Yeyi
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 'fit-content',
                    //position: 'absolute',
                    left: 0, //Change by yeyi ;)
                    backgroundColor: 'white', //Added by Yeyi
                    padding: 2, //Added by Yeyi
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={handleEmailBlur}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    id="password"
                    autoComplete="current-password"
                    type={passwordShown ? 'text' : 'password'}
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPasswordClick}>
                            {passwordShown ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={login}
                  >
                    Login
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="/forgotpassword" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
                    <Grid item>
                      <Copyright sx={{ mt: 8, mb: 4 }} />
                    </Grid>
                  </Grid>
                </Box>
              </form>

          </div>
        )}
      </>):(<ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h5>Please set a new password </h5>

            <TextField
              margin="normal"
              required
              fullWidth
              label="new password"
              value={newPassword}
              // type={passwordShown ? 'text' : 'password'}
              type="text"
              onChange={(e) => {
                validatePassword(e)
              }}
            />

            <TextField
              type="text"
              margin="normal"
              required
              fullWidth
              label="confirm password"
              value={duplicate}
              onChange={(e) => {
                setDuplicate(e.target.value)
              }}
              onBlur={validatePassword}
            />

            <Button
              fullWidth
              variant="contained"
              disabled={!enablesave}
              className="btn btn-primary btn-block"
              onClick={handleSave}
            >
              save
            </Button>
            <div></div>
          </Box>
          <Box>
            {/* TODO need improvmeents to be reusable */}
            {/* <PasswordValidatorComponent password={newPassword} /> */}

            <>
              {password_requirements.map((req, index) => {
                return (
                  <>
                    <div>
                      {req}
                      {conditionsArray[index] ? <CheckCircleIcon /> : <></>}
                    </div>
                  </>
                )
              })}
            </>
          </Box>
        </Container>
      </ThemeProvider>)}
      </div>
      {/*-----------------------------------------------------*/}
      {/*SESSION LOGIN RIGHT CONTAINER ENDS HERE              */}
      {/*-----------------------------------------------------*/}
    </div>
  )
}

export default SessionLogin
